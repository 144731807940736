<template>
  <div :style="{width}">
    <div v-if="label" class="input-label f-align-center mb1">
      {{ $t(label) }}
      <span v-if="required" class="required-field ml1">*</span>
    </div>
    <!-- Модификация v-on не описана в справке, нашел на гитхабе -->
    <div
      class="input-wrapper f-align-center"
      :class="{rounded, noBorder, disabled,
               error: error || hasError, 
               'bg-white': !readOnly, 
               'bg-light-grey': readOnly, 
               [size]: true,
               'pr1 pl4': rounded,
               px2: !rounded,
      }"
    >
      <div v-if="maxlength" class="text-12 text-mid-grey abs" style="right: 0;top: -20px;">
        {{ +maxlength - (value.length || 0) }} / {{ +maxlength }}
      </div>
      <SvgIcon v-if="search" width="24" icon-name="search" class="grey mr1" />
      <slot v-else name="prepend" />
      <div v-if="readOnly" class="readonly-field text-grey">{{ value }}</div>
      <input
        v-else-if="!textarea && !numeric"
        :id="id"
        :name="name"
        :value="v$.value.$model"
        :inputmode="mode"
        :maxlength="+maxlength"
        :disabled="disabled"
        :placeholder="$t(search ? 'common.find' : placeholder)"
        :autocomplete="autocomplete"
        :style="{fontSize, height: height}"
        :type="password ? 'password' : 'text'"
        v-on="listeners" 
      />
      <textarea
        v-else-if="textarea"
        v-bind="$attrs"
        :id="id"
        :disabled="disabled"
        :placeholder="$t(placeholder)"
        :maxlength="+maxlength"
        :value="v$.value.$model"
        :rows="rows"
        v-on="listeners"
      />
      <!-- Для ввода денежных значений в рублях -->
      <VueNumber
        v-else-if="numeric"
        :value="v$?.value?.$model"
        :min="minNum"
        :max="maxNum"
        v-bind="numConfig"
        v-on="listeners"
      />
      <slot v-if="$slots.append" name="append" />
    </div>
    <div v-if="hasError && !errMessage" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate?.req || 'common.errors.required') || 'Поле должно быть заполнено' }}
    </div>
    <div v-if="v$.value?.minLength?.$invalid" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate?.minlength[1]) }}
    </div>
    <div v-if="v$.value?.maxLength?.$invalid" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate?.maxlength[1]) }}
    </div>
    <div v-if="v$.value?.url?.$invalid" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate.url) }}
    </div>
    <div v-if="v$.value?.email?.$invalid" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate.email) }}
    </div>
    <div v-if="v$.value?.alphaNum?.$invalid" class="err-block text-red text-12" style="margin-bottom: -18px;">
      {{ $t(validate.alphaNum) }}
    </div>
    <!-- Любая произвольная ошибка текстом в пропсе errMessage -->
    <slot v-if="errMessage" name="error" :error="{errMessage}">
      <div class="text-red text-12">{{ $t(errMessage) }}</div>
    </slot>
  </div>
</template>

<script>
import { component as VueNumber } from '@coders-tm/vue-number-format';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, url, email, alphaNum } from 'vuelidate/lib/validators'
// import {watch} from 'vue'

export default {
  name: "AppInput",

  components: {
    SvgIcon: () => import('@/components/common/svg-icon'),
    VueNumber,
  },

  props: {
    label: String,
    name: String,
    mode: String, // numeric, decimal, text, tel, search, email, url
    password: Boolean,
    minlength: [Number, String],
    maxlength: [Number, String],
    minNum: {
      type: Number,
      default: 0,
    },
    maxNum: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    id: String, // для автоподстановки
    readOnly: Boolean,
    numeric: Boolean,
    textarea: Boolean,
    placeholder: String,
    rows: { type: Number, default: 4},
    required: Boolean,
    validate: Object,
    error: Boolean, // просто признак ошибки, чтоб не выводить под полем текст, а только сделать красным
    // сообщение об ошибке под полем
    errMessage: String,
    disabled: Boolean,
    search: Boolean,
    rounded: Boolean,
    autocomplete: {
      type: String,
      default: 'on',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    size: { type: String, default: 'medium' }, // small, medium, large
    width: String,
    height: String, // лучше использовать size
    bg: String,
    noBorder: Boolean,
    value: {
      type: [String, Number],
      required: true,
      default: '',
    }
  },

  setup () {
    const v$ = useVuelidate()
    // watch(() => v$.$invalid, (val,prev) => {
    //   emits('update:error', val)
    // })
    return { v$ } 
  },


  validations() {
    const value = {}
    if (this.validate) {
      Object.keys(this.validate).forEach(key => {
        switch (key) {
          case 'req':
            value.required = required; break;
          case 'url':
            value.url = url; break;
          case 'email':
            value.email = email; break;
          case 'alphaNum':
            value.alphaNum = alphaNum; break;
          case 'minlength':
            value.minLength = minLength(this.validate[key][0]); break;
          case 'maxlength':
            value.maxLength = maxLength(this.validate[key][0]); break;
          default:
            break;
        }
      })
    }
    return {value}
  },
  
  data() {
    return {
      numConfig: {
        decimal: ",",
        separator: ".",
        prefix: "",
        suffix: " руб",
        precision: 0,
        nullValue: "",
        reverseFill: false,
        min: 0,
      },
    }
  },
  
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    },
    hasError() {
      const hasVal = typeof this.value == 'string' ? this.value?.trim() != '' : this.value != 0
      return (this.required || this.validate?.req) && !hasVal
    },
  },
  watch: {
    'v$.$invalid': {
      handler(val) {
        this.$emit('update:error', val)
      },
      immediate: true,
    },
  },
  methods: {
    updateValue(e) {
      const val = this.numeric ? e : e?.target?.value || ''
      // this.v$.value.$touch()
      this.$emit("input", val);
    },
  }
}
</script>

<style scoped lang="scss">
.input-label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
  .required-field {
    color: red;
  }
}
.input-wrapper {
  border: 1px solid #d9d9d9;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;
  -webkit-transition: all 0.3s;
  &.small {
    height: 24px;
    &.rounded {
      border-radius: 12px;
    }
  }
  &.middle {
    height: 32px;
    &.rounded {
      border-radius: 16px;
    }
  }
  &.large {
    height: 40px;
    &.rounded {
      border-radius: 20px;
    }
  }
  
  &:focus-within {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &.noBorder {
    border: none;
  }
  &.disabled {
    border-color: #efefef;
    background-color: whitesmoke;
  }
  &.error {
    border-color: lightpink;
    &:focus-within {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 24, 55, 0.2);
      box-shadow: 0 0 0 2px rgba(255, 24, 55, 0.2);
    }
  }
}
input, textarea {
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  background-color: transparent;
  width: 100%;
  border: none;
  &::placeholder {
    color: lightgray;
  }
  &[disabled] {
    color: darkgrey;
  }
  &:focus-visible {
    outline: none;
  }
}
textarea {
  height: auto;
}
input {
  &.rounded {
    border-radius: 16px;
  }
}
.err-block {
  // margin-top: -5px;
  // margin-bottom: 5px;
}
// особые стили иконок
i.icon {
  &.search {
    // 
  }
}
.readonly-field {
  font-size: 10pt;
  height: 32px;
  padding: 6px 0px;
}
</style>