var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"centered":"","destroy-on-close":"","closable":_vm.closable,"mask-closable":_vm.maskClosable,"visible":_vm.value,"width":Number.isInteger(_vm.width) ? `${_vm.width}px` : _vm.width,"body-style":{
    ..._vm.bodyStyle,
    padding: '24px',
    height: Number.isInteger(_vm.height) ? `${_vm.height}px` : _vm.height,
    maxHeight: Number.isInteger(_vm.maxHeight) ? `${_vm.maxHeight}px` : _vm.maxHeight,
    overflow: _vm.overflow,
  },"footer":_vm.hasFooter ? _vm.$slots.footer : null},on:{"cancel":_vm.close}},[_c('div',{staticClass:"f-align-center",class:_vm.titleClass,attrs:{"slot":!_vm.titleInBody ? 'title' : undefined},slot:!_vm.titleInBody ? 'title' : undefined},[(_vm.backIcon)?_c('SvgIcon',{staticClass:"primary mr6 pointer",attrs:{"icon-name":"chevron_big_left"},on:{"click":_vm.backAction}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"f-grow-1 mr8",class:{'flex-center': _vm.titleCentered}},[_vm._v(_vm._s(_vm.$t(_vm.title)))])],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"backdrop-loader"},[_c('Loader')],1):_vm._e(),_vm._v(" "),_c('SvgIcon',{staticClass:"primary",class:{mt3: _vm.titleInBody},attrs:{"slot":"closeIcon","icon-name":"cross","width":"16","viewBox":"0 0 16 16"},on:{"click":_vm.close},slot:"closeIcon"}),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.hasFooter)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"d-flex"},[_vm._t("extra-btns"),_vm._v(" "),(_vm.deleting)?_c('Button',{attrs:{"icon":"trash_full","link":"","danger":"","text":"common.delete"},on:{"click":function($event){return _vm.$emit('delete')}}}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"flex-end gap4 f-grow-1"},[_c('Button',{attrs:{"secondary":"","text":_vm.cancelProps.text || 'common.cancel'},on:{"click":_vm.close}}),_vm._v(" "),(_vm.okProps.visible !== false)?_c('Button',{attrs:{"loading":_vm.btnLoading,"primary":"","disabled":_vm.okProps.disabled || false,"text":_vm.okProps.text || 'common.save'},on:{"click":function($event){return _vm.$emit('save')}}}):_vm._e()],1)]),_vm._v(" "),_vm._t("subfooter")],2):_c('div',{attrs:{"slot":"footer"},slot:"footer"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }