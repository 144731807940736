export default {
	// Загальні рядки для всіх розділів
	common: {
		menu_title: 'Меню',
		menu: {
			notifications: 'Повідомлення',
      wall:'Стіна',
			address:'Адресна книга',
			profile:'Особистий кабінет',
			groups:'Групи',
			calendar:'Заходи',
			knowl_base:'База знань',
			vacancies:'Вакансії',
			services:'Сервіси',
			polls:'Опитування',
			chat:'Чати',
			structures:'Структура',
			no_silent:'Не мовчи',
			ideas:'Запропонувати ідею',
			employees:'Адміністрація',
			clients:'Клієнти',
			admin:'Адмін-панель',
			shop: 'Магазин',
      task_tracker: 'Дошка завдань',
      adaptation: 'Адаптацiя',
			vacations: 'Відпусткi',
		},
		error:'Помилка',
		errors: {
			common:'Сталася помилка',
			file_upload:'Помилка завантаження файлу',
			file_dwn:'Помилка при завантаженні',
			file_save:'Помилка збереження',
			no_bucket:'Немає бакета з ім\'ям',
			file_size:'Помилка: розмір файлу перевищено',
			file_del:'Помилка видалення файлу',
			files_del:'Помилка видалення файлів',
			file_size2:'Файли більше {size} завантажувати не можна',
			user_not_found:'Користувача не знайдено',
			file_not_found: 'Файл не знайдений або видалений',
			user_change:'Помилка заміни користувачів',
			sending:'Помилка при відправленні',
			fill:'Помилка заповнення',
			required: 'Поле не заповнене',
			data:'Помилка даних',
			data_full:'Під час перевірки даних виявлено помилки',
			no_val: 'Не вказано',
      title:'Не вказано заголовок',
      no_access: 'Немає доступу',
      img_size: 'Неправильний розмір',
			empty_fields: 'Не заповнені деякі поля даних',
			bucket_limit: 'Досягнуто межу сховища ({size})! Доступно {available}',
			no_komment_owner_kind: 'Не вказано тип власника стрічки коментарів',
		},
		dates: {
			today: 'Сьогодні',
			yesterday: 'Вчора',
			tomorrow: 'Завтра',
			in: 'Через',
			ago: 'назад',
			until: 'До',
			years: "0 років | {count} рік | {count} року | {count} років ",
			months: "0 місяців | {count} місяць | {count} місяця | {count} місяців",
			weeks: "0 тижнів | {count} тиждень | {count} тижня | {count} тижнів",
			days: "0 днів | {count} день | {count} дня | {count} днів",
			hours: '0 годин | {count} годину | {count} години | {count} годин',
			minutes: '0 хвилин | {count} хвилина | {count} хвилини | {count} хвилин',
			seconds: '0 секунд | {count} секунда | {count} секунди | {count} секунд',
			less_than_month: 'менше місяця'
		},
		dates_f: {
			years: "0 | рік | року | років ",
			months: "0 | місяць | місяця | місяців",
			days: "ні | день | дня | днів",
			weeks: "0 | тиждень | тижня | тижнів",
			hours: '0 | годину | години | годин',
			minutes: '0 | хвилина | хвилини | хвилин',
			seconds: '0 | секунда | секунди секунд',
		},
		time: {
			M: 'міс',
			d: 'дн',
			h: 'год',
			m: 'мін',
			s: 'сік',
		},
		time_f: {
			M: 'місяців',
			d: 'днів',
			h: 'годин',
			m: 'хвилин',
			s: 'секунд',
		},
		begin: 'Початок',
		end: 'Закінчення',
		select:'Вибрати',
		select_many: 'Вибрати кілька',
		delete:'Видалити',
		edit:'Редагувати',
		restore:'Відновити',
		add:'Додати',
		create:'Створити',
		change:'Змінити',
		update:'Оновити',
		save:'Зберегти',
		rename: 'Перейменувати',
    replace: 'Перемістити',
		send: 'Надіслати',
    edit_rights: 'Налаштувати права доступу',
    apply:'Застосувати',
		download: 'Завантажити',
    export: 'Вивантажити',
    cancel:'Скасувати',
		canceling:'Скасування',
		yes:'Так',
		no:'Ні',
		good: 'Добре',
    turn_on:'Включити',
		turn_off:'Вимкнути',
		back: 'Назад',
    file_loading:'Завантаження файлу',
		file_deleted:'Файл видалений',
		files_deleted:'Всі файли видалені',
		find:'Шукати',
		flush:'Скинути',
		open: 'Відкрити',
		close:'Закрити',
		show:'Показати',
		load: 'Завантажити',
		empty_m:'Не вказано',
		empty_f:'Не вказано',
		confirm:'Підтвердити',
		i_confirm:'Підтверджую',
		extra_info:'Додаткова інформація',
		link: 'Посилання',
		title:'Заголовок',
		comment:'Коментар',
		description: 'Опис',
		save_changes:'Зберегти зміни',
		saved:'Збережено',
		delete_all:'Видалити все',
		show_all: 'Показати все',
		expand: 'Розгорнути',
		collapse: 'Згорнути',
		attach: 'Додати файли',
		uploader_text: 'Прикріпіть та перетягніть файли сюди',
    uploader_info: 'Будь-який тип файлів до 100 мегабайт',
		file_too_big:'Розмір файлу занадто великий',
		file_up:'Файл завантажений',
		file_uploaded_succ:'{file} завантажено успішно',
		file_upload_fail:'{file} не завантажений',
		adjust_image: 'Завантажте та налаштуйте картинку',
		enter_text:'Введіть текст',
		fields_not_filled:'Не заповнені поля',
		recievers:'Одержувачі',
		data_sent:'Дані надіслані',
		data_upd:'Дані оновлені',
		data_ch:'Дані змінені успішно',
		data_saved: 'Дані збережені успішно',
    mails_sent_to_users:'Листи надіслані вказаним користувачам',
		people:'люд.',
		sel_many:'Вибрані',
		members_pl: 'нікого | учасник | учасника | учасників',
		pages:'Всього {total} сторінок',
		confirm_del:'Підтверджуєте видалення?',
		confirm_cancel: 'Підтверджуєте скасування?',
		insert_url: 'Вставте посилання',
		not_found: 'Не знайдено',
		empty_here: 'Тут поки нічого немає',
		unsaved_files: 'Незбережені файли',
		ph: {
			title: 'Введіть назву',
			date: 'Введіть дату',
			num: 'Введіть число',
			str: 'Введіть рядок',
			url: 'Введіть URL',
		},
    posts: {
			title: 'Створити публікацію',
      wall_company_news: 'Новина компанії',
      thanking:'Пост-подяка',
			thanking_hint:'Введіть мінімум 30 символів і обов\'язково <br> згадайте співробітника, якому хочете подякувати',
			ext_editor:'Розширений редактор',
			send_hint:'Надіслати повідомлення: Ctrl+ENTER, з нового рядка: Shift+ENTER',
			append_file:'Завантажити файл',
			append_files:'Завантажити файли',
			file_size_hint: 'Файл не повинен перевищувати 100 МБ',
      attachments: 'Вкладення',
      media: 'Зображення та відео',
      documents: 'Документи та інші файли',
			ph_thanking:'Згадайте того, кому бажаєте віддячити',
			ph_text:'Напишіть текст повідомлення. Використовуйте @, щоб когось згадати',
			message:'Повідомлення',
			send:'Опублікувати',
			like:'Подобається',
			dislike:'Не подобається',
			comments: 'Коментарі',
			comment:'Коментувати | {n} коментар | {n} коментарі | {n} коментарів',
			add_comment: 'Додати коментар',
			show_all: 'Показати всі коментарі',
			show_more: 'Коментарів немає | Показати ще {n} комментар | Показати ще {n} комментаря | Показати ще {n} комментарiв',
      answer:'Відповісти',
			more_act: 'Ще',
      hide:'Приховати',
			show:'Показати {len} відповідь(i)',
			created:'Пост створений:',
			deleted:'Пост видалений',
			more:'Докладніше',
			share:'Поділитися',
      copy: 'Копіювати посилання',
			link_copied:'Посилання скопійоване',
			link_not_copied:'Посилання не скопійоване',
			post_actions:'Дії з постом',
			comment_actions:'Дії з коментарем',
			mentioned_empls:'Згадані співробітники:',
			confirm_comment_del: 'Чи підтверджуєте видалення коментаря?',
			comment_deleted:'Коментар видалено',
			input_more30:'Введіть більше 30 символів',
			choose_person_to_thank:'Вкажіть у пості одну людину, кому призначена подяка',
			system_msg:'Системне сповіщення',
			edited:'Відредаговано',
			and_yet:'і ще',
			show_comments:'Розгорнути коментарі (ще {len})',
			no_comments: 'Приховати коментарі',
      hide_comments:'Згорнути коментарі',
			edit_post:'Редагувати пост',
			editing_post: 'Редагування посту',
			edit_comment:'Редагувати коментар',
			ch_files:'Змінити файли',
			text_err:'Не вказано текст повідомлення',
			err_size:'Файли більше 20 МБ не можна прикладати до постів',
			load_more: 'Завантажити ще...',
      back_to: {
        user: 'Назад у блог співробітника',
        feed: 'Назад у стрічку',
        company: 'Назад на стіну компанії',
        group: 'Назад до групи',
      },
      emoji: {
        search: "Пошук",
        notfound: "Смайли не знайдені",
        categories: {
          search: "Результати пошуку",
          recent: "Часто використовуються",
          smileys: "Смайли",
          people: "Люди",
          nature: "Тварини та природа",
          foods: "їжа та напої",
          activity: "Активності",
          places: "Подорожі",
          objects: "Будова",
          symbols: "Символи",
          flags: "Прапори",
          custom: "Кастом"
        }
      },
	  mentions_no_data: 'Введіть ім\'я або прізвище користувача'
	},
		list: {
			find_by_fullname:'Пошук по ПІБ',
			no_position:'ні посади',
			no_selected:'Ніхто не обраний',
		},
		login_bar: {
			show_msg_in_console:'Показувати повідомлення в консолі:',
			logout:'Вийти',
		},
		notifs: {
			group: {
				new:'Створено нову групу',
			},
			empl: {
				new:'Доданий новий співробітник',
			},
			feed: {
				need_update:'Потрібно оновити стрічку',
			},
			post: {
				blog_new:'Новий пост у блозі',
				blog_del:'Пост у блозі видалений',
				blog_edited:'Змінено пост у блозі',
				company_new:'Вийшла новина по компанії',
				company_edited:'Новину по компанії змінено',
				feed_new:'Новий пост у стрічці новин',
				feed_edited:'Змінено пост у стрічці новин',
				group_new:'Новий пост у групі',
				group_del:'Пост у групі видалений',
				group_edited:'Змінено пост у групі',
				descr_news:'Перевірте стрічку новин',
				descr_feed:'Перевірте стрічку подій',
				descr_blog:'Перевірте блог співробітника',
				descr_group:'Перевірте групу',
			},
			data_copied: 'Дані скопійовані',
		},
		mood: {
			title:'Як ваш настрій?',
			rate:'Оцініть ваш настрій у момент приходу на роботу від 1 до 10',
			thanks:'Дякую! Дані про настрої враховані!',
      filename: 'Настрій сотрудников в {name} за {begin}-{end}',
      excel_sheet: 'Настрій співробітників',
      err_excel: 'Помилка при експорті даних настроїв у Excel',
		},
		tags: {
			title: 'Мітки (теги)',
			add: 'Додати тег',
			new: 'Новий тег',
			create: 'Створити нову мітку',
		},
		e404:'Упс, здається ця сторінка більше не актуальна. Можливо змінився її адреса або об\'єкт не існує',
		to_main_page:'На головну',
		no_recipient_yet: 'Поки що жодного із співробітників не призначено...',
    one_select: 'Виберіть значення',
		select_date: 'Виберіть дату',
		list_select: 'Виберіть зі списку',
		empl_select: 'Виберіть співробітників',
    loading: 'Завантажується...',
		code_copied: 'Код скопійовано',
    footer: {
      tech_support: 'Технічна підтримка',
    },
	cover_size: 'Завантажте обкладинку з шириною 3420px та висотою від 250px у форматі PNG або JPG'
	},
	login: {
		title:'Вхід',
		ws:'Код компанії',
		copy: 'Всі права захищені',
		site: 'Основний сайт',
		policy: 'Політика конфіденційності',
		agree: 'Приймаю',
		terms: 'Умови використання',
		required:'Це поле є обов\'язковим',
		no_email:'Користувач з таким email не зареєстрований',
		pwd:'Пароль',
		pwd2:'Підтвердження пароля',
		pwd_err:'Пароль неправильний',
		auth:'Увійти в систему',
		agreement1:'Заходячи в програму ви автоматично погоджуєтеся з',
		agreement2:'угодою користувача',
		pers_agreement: 'і даєте згоду',
		pers_agr: 'на обробку персональних даних',
		forgot:'Забули пароль?',
		lang:'Змінити мову',
		err: {
			enter:'Помилка входу',
			no_login_pwd: 'Не вказано ім\'я або пароль',
			user_del:'Користувач видалено',
			client_blocked: 'Компанія заблокована',
      server:'Помилка на сервері',
			data: 'Помилка під час перевірки',
			restore: 'Помилка відновлення пароля',
			pwd_change: 'Помилка зміни пароля',
			pwd: 'Вкажіть новий пароль',
 			pwd2: 'Вкажіть новий пароль ще раз',
			data_send: 'Помилка при надсиланні даних',
			pwd_equal: 'Переконайтеся, що паролі збігаються',
    },
		restore_title: 'Відновлення пароля',
		restore_sub: 'Ми надішлемо посилання для скидання пароля на вашу електронну пошту',
		restore_ok: 'Перевірте пошту та перейдіть за посиланням для зміни пароля',
		letter_sent: 'Лист надісланий',
		back: 'Повернутись назад',
		reset: 'Скидання пароля',
		reset_ok: 'Пароль змінено',
		reset_msg: 'Новий пароль успішно встановлено'
	},
	// таблиці
	tbl: {
		// колонки
		cols: {
			// загальні для багатьох таблиць
			common: {
				name:'Назва',
				descr:'Опис',
				date:'Дата',
				time:'Час',
			},
			admin: {

      },
    },
	},
	grant_access: {
		title: 'Надати доступ',
		btn: 'Вибрати права',
		all: 'Усі співробітники',
		all_heads: 'Усі керівники',
		read_level: 'Читання',
		write_level: 'Запис',
		no_level: 'Недоступно',
		all: 'Доступно всім',
    all_mngrs: 'Всі керівники',
    types: {
			for_all: {
				title: 'Доступний всім',
				desc: 'Усі співробітники компанії'
			},
			search_by_head: {
				title: 'Пошук за керівниками',
				desc: 'Лише керівники відділів',
				search_placeholder: 'Введіть ПІБ'
			},
			search_by_name: {
				title: 'Пошук на ім\'я та посаду',
				desc: 'Якщо ви хочете додати когось конкретно',
				search_placeholder: 'Введіть ПІБ, посада'
			},
			search_by_department: {
				title: 'По відділах',
				desc: 'Доступ буде у всіх співробітників відділів',
				search_placeholder: 'Введіть назву відділу'
			},
			search_by_city: {
				title: 'Містами',
				desc: 'Виберіть конкретну філію зі структури',
				search_placeholder: 'Вкажіть місто',
			},
			search_by_time: {
				title: 'За часом роботи',
				desc: 'Сортування за часом роботи',
				search_placeholder: 'Вкажіть час',
			},
			manual_search: {
				title: 'Ручна фільтрація',
				desc: 'Підберіть фільтри під себе',
				search_placeholder: 'Вкажіть дані вручну',
			},
		},
    save: 'Надати доступ',
    set_ok: 'Права встановлені успішно',
		error: 'Помилка прав',
    depts: 'Підрозділи',
    nothing_selected: 'Нічого не вибрано, потрібно вибрати якісь права',
	},
	// Стіна компанії
	company: {
		feed: {
			company_feed:'Новини компанії',
			thank: 'Подяка',
      user_feed:'Моя стрічка подій',
			type_unknown:'Нерозпізнаний тип події',
			tooltip_user: 'Пости у групах та блогах, на які ви підписані. Повідомлення про дні народження, запрошення на заходи та інші новини',
      tooltip_company: 'Тільки важливі новини компанії',
			bd_today: 'Сьогодні день народження святкують',
		},
		mention: {
			in:'Згадка в {title}',
			group:'Згадка у групі',
			blog:'Згадка у блозі',
			comment_answer_of:'Згадка у відповіді на коментар до',
			post_in:'посту в',
			del_post:'видаленому посту',
			comment_news:'Згадка у коментарі до новини по компанії',
			other:'Інша згадка',
			text:'Текст повідомлення',
		},
		comments: {
			i_new_empl:'Я - новий співробітник',
			blog_post:'Коментар посту в блозі',
			feed_post:'Коментар посту у стрічці',
			group_post:'Коментар посту в групі',
			blog_answer:'Відповідь на ваш коментар у блозі',
			type_not_defined:'Тип запису з коментарем не визначений',
		},
		title: {
			news_comment:'коментарі до новини',
			post_comment:'коментарі до посту',
			news:'новини по компанії',
			group_comment:'коментарі в групі',
			blog_comment:'коментарі в блозі',
			feed_post:'пості на стіні',
			feed_post_comment:'коментарі до посту на стіні',
		},
		places: {
			your_blog:'у вашому блозі',
			post_del:'віддаленому пості',
			news:'новинах компанії',
			user_blog:'у блозі співробітника {name}',
			group:'у групі {name}',
			feed:'загальній стрічці',
		},
		msg: {
			group_new:'створив нову групу',
			group_del:'видалив групу',
			group_edit:'змінив дані групи',
			title: {
				new_group:'Нова група',
				del_group:'Група видалена',
				new_empl:'Новий співробітник',
			},
		},
		notifs: {
			new_group:'Створив нову групу',
			del_group:'Вилучив групу',
			new_empl:'Вітаємо нового співробітника',
			add_post: 'додав пост',
			post_in_blog: 'у своєму блозі',
			post_in_group: 'у групі',
			post_in_feed: 'у стрічці',
		},
    widgets: {
			title: 'Віджети на стіні',
			thanks: {
				title: 'ПОДЯКИ',
				descr: 'Співробітники, які найчастіше отримували подяки',
				no: 'Подяки поки що не залишені. Ви можете тегнути колегу в пості-подяки',
			},
			bdays: {
				title: 'Дні народження',
				today: 'Сьогодні!',
				today_more: 'Ще у {n} сьогодні',
				month_more: 'Майже ще {n} цього місяця',
				descr: 'Співробітники, які святкують день народження сьогодні або в найближчі дати',
				no: 'Сьогодні ніхто не святкує день народження',
				congr: 'Привітати',
				congrats: 'Вітаю, тебе @{name} з днем народження! 🥳🎁🎈',
				next: 'Всі дні народження',
			},
			links: {
        title: 'Корисні посилання',
        descr: 'Будь-які посилання, які можуть бути корисними',
        no: 'Немає посилань',
      },
			groups: {
				title: 'Важливе в групах',
				descr: 'Пости або інша важлива інформація у групах',
				no: 'Поки немає нічого важливого в групах',
			},
			events: {
				title: 'Календар',
				descr: 'Події або заходи, призначені на сьогодні',
				no: 'У календарі немає подій на сьогодні',
			},
			kb: {
				title: 'Важливе в базі знань',
				descr: 'Важливі статті або файли в базі знань',
				no: 'У базі знань поки що немає важливої інформації',
			},
			polls: {
				title: 'Важливе в опитуваннях',
				descr: 'Важливі опитування думки для співробітників',
				no: 'Зараз немає опитувань',
			},
			blogs:{
				title: 'Важливе в блогах',
				descr: 'Пости в блогах, позначені як важливі',
				no: 'Зараз немає важливої інформації в блогах',
			},
			vacancies: {
				title: 'Актуальні вакансії',
				descr: 'Вакансії по компанії, які зараз відкриті',
				no: 'Поки в компанії немає вакансій',
			},
			other: {
				title: 'Важливе',
				descr: 'Інша важлива інформація',
				no: 'Важливої інформації поки що немає',
			},
		},
		welcome:'Ласкаво просимо до команди!',
	},
	// Адмін-панель
	admin_panel: {
		title:'Панель адміністратора',
		tabs: {
			all_data:'Всі дані',
			mail:'Перевірка пошти',
			notif_settings:'Налаштування оповіщень',
			clean:'Очищення файлів',
			logs:'Логи',
      service: 'Сервісна панель'
		},
		tbl: {
			data_mng: {
				collection:'Колекція',
				docs_count:'Кількість записів',
				actions:'Дії',
			},
			posts: {
				_id:'№',
				message:'Текст',
				author_ref:'Автор',
				created:'Дата / час',
				parent:'Парент',
			},
			users: {
				_id:'№',
				fullName:'ПІБ',
				_email:'Ел . пошта',
				roles:'Ролі',
			},
			actions: {
				actions:'Дії',
			},
			notifs: {
				fullName:'ПІБ',
				addUser:'Додавання користувача',
				publications:'Публікації',
				mentions:'Згадки',
				subscribe:'Підписки',
				actions:'Операції',
			},
			cleaning: {
				filename:'Ім\'я файлу',
				name:'Шлях',
				lastModified:'Дата зміни',
				size:'Розмір',
			},
			logs: {
				date:'Дата / Час',
				job_code:'Код події',
				message:'Повідомлення',
			},
		},
		tables: {
			users:'Користувачі',
			groups:'Групи',
			posts:'Пости',
			messages:'Повідомлення',
			events:'Події',
		},
		done: 'Виконано',
    no_tbl_sel:'Таблиця не вибрана',
		client_data_mng:'Керування даними клієнта',
		test_data_notif:'Ця сторінка призначена для тестових даних, не для реальних клієнтів!',
		del_data:'Видалити дані',
		del_data_filter:'Видалити за фільтром',
		del_coll:'Видалення колекції',
		del_confirm:'Дані будуть видалені безповоротно, видалити?',
		data_deleted:'Дані в колекції видалені:',
		data_del_err:'Помилка видалення даних',
		mail: {
			header:'Тестове надсилання листа на ящики',
			test:'Тест пошти',
			not_sel:'Не вибраний e-mail',
			need_sel:'Потрібно вибрати як мінімум одну адресу',
			sent:'Пошта відправлена',
			check:'Перевірте пошту',
			error:'Відправка не вдалася',
      sendsay_send: 'Перевірка SendSay',
      template_send: 'Перевірка html-tmpl',
		},
		cleaning: {
			check:'Перевірити файли',
			del:'Видалити файли',
			hint:'Увага! Перевіряються та видаляються файли всіх клієнтів, не тільки поточного воркспейсу',
			deleted:'Видалено {deleted} файлів',
			error:'Помилка видалення файлів',
		},
    service: {
      polls_fix: 'Оновити опитування',
			articles_fix: 'Встановити права',
      articles_fixed: 'Права на статті (папки, вакансії) встановлені «Для всіх»',
			service_op: 'виконати операцію',
    },
    jobs: {
      confirm_del: 'Видалити завдання?',
    },
	},
	// Адресна книга
	address: {
		title:'Адресна книга',
		title_R: 'адресної книги',
		no_empls:'Співробітники не знайдені',
		enter_search:'Введіть слово для пошуку',
		filter_by:'Фільтр за колонкою',
		empty: 'Зараз ваша адресна книга порожня, але незабаром у ній з\'являться контакти ваших колег',
    tbl: {
			empls: {
				name:'ПІБ співробітника',
				positions:'Посада',
				phone:'Телефон',
				email:'Пошта',
				city: 'Місто',
				tg: 'Telegram',
				departments:'Підрозділ',
				cabinet: 'Кабінет',
        actions: 'Дiї',
			},
		},
		col: {
			email: 'Пошта',
			departments: 'Підрозділ',
			phone: 'Телефон',
			city: 'Місто',
			cabinet: 'Офіс (кабінет)',
			tg: 'Telegram',
		},
    create_chat: 'Створити чат',
		pin_user: 'Закріпити співробітника',
		unpin_user: 'Відкріпити співробітника',
		pin_move_user: 'Перемістити позицію',
		select_new: 'Виберіть нову позицію',
	},
	// Профіль співробітника
	profile: {
		dept_adm:'Адміністративний підрозділ',
		dept_func:'Функціональний підрозділ',
		dept_prj:'Проектний підрозділ',
		depts: 'Підрозділи співробітника',
		my_subscribers:'Мої підписники',
		subscribers: 'Немає передплатників | {count} передплатник | {count} передплатника | {count} передплатників',
		subscribed:'Вже оформлено підписку',
		subscribe:'Підписатися',
		unsubscribe: 'Отписатися',
		new_subscribe: 'Новий підписач',
		no_subscribers: 'Поки немає передплатників, перегляньте всіх співробітників компанії в ',
		tabs: {
			main: 'Основне',
			extra: 'Додатково',
			soc_nets: 'Соціальні мережі',
			for_hr: 'Кадрові документи',
			files_hr: 'Файли HR',
			profile: 'Контакти',
			notifs: 'Повіщення',
			subscribers: 'Підписники',
		},
		blog: 'Блог',
		edit_empl:'Редагувати користувача',
		no_position:'Посада не вказана',
		no_info:'Інформація про себе не заповнена',
		edit_data:'Змінити дані',
		editing: 'Редагування профілю',
		set_status: 'Додати статус',
		add_status_title: 'Додавання статусу',
		status_ph: 'Введіть текст статусу',
		chat:'Написати повідомлення в чат',
		contacts:'Контакти',
		int_phone:'Внутрішній телефон',
		no_add_data:'Немає додаткових даних',
		manager: 'Керівник',
    managers:'Керівники',
		no_manager: 'Керівник відділу не призначений',
		empls:'Співробітник',
		colleagues: 'Колеги',
		status_set: 'Статус успішно встановлений',
		open: 'Перейти до профілю',
		birthday: 'День народження: {date}',
		no_birthday: 'День народження не вказано',
		working_in_company: 'У компанії',
		and_more: 'І ще {len} людина',
		no_location: 'Локація не визначена',
		load_pic: 'Завантажити обкладинку',
		del_pic: 'Видалити обкладинку',
		pic_change: 'Змінити фото профілю',
		pic_updated: 'Фото змінено',
		reset_to_hrm: 'Скинути дані',
		reset_hrm_confirm: 'Ви дійсно хочете скинути дані, введені вручну?',
		reset_hint: 'Видалити змінені дані та показувати дані із ЗУП',
		data_has_diffs: 'Дані відрізняються від завантажених із 1С ЗУП',
		reset_end_ok: 'Дані співробітника повернуті до даних з 1С',
		email_copied: 'Email скопійовано',
		genders: {
			man: 'Чоловічий',
			woman: 'Жіночий',
			not_selected: 'Не вказано',
		},
		woman_decret: 'Співробітниця у декреті',
		edit: {
			name:'Ім\'я',
			surname:'Прізвище',
			midname: 'По батькові',
			position: 'Посада',
			vac_days: 'Залишок відпустки',
			position_ph: 'Менеджер',
			empl_chief: 'Керівник',
			cabinet: 'Кабінет',
			email: 'Пошта',
			phone: 'Номер телефону',
			lang: 'Мова',
			change_ava: 'Змінити аватарку',
			since: 'У компанії с',
			city: 'Місто',
			gender: 'Пол',
		},
		hints: {
			name:'Введіть ім\'я',
			surname:'Введіть прізвище',
			midname: 'Введіть по батькові',
			email: 'Вкажіть E-mail!',
			cabinet: 'Вкажіть кабінет',
			position: 'Вкажіть посаду',
			vac_days: 'Кількість днів',
			correct_email: 'Вкажіть свій e-mail!',
			phone: 'Ваш номер телефону',
			lang: 'Виберіть мову',
      gender: 'Вкажіть стать',
		},
	},
	// Групи
	groups: {
		title:'Групи',
		group:'група',
		only_mine: 'Тільки мої групи',
		feed:'Стрічка',
		about:'Про групу',
		create:'Створити групу',
		ph_name: 'Книжковий клуб',
		ph_descr: 'Збираємось і обговорюємо улюблені книги та авторів',
		join:'Вступити',
		reject:'Відхилити',
    accept:'Прийняти',
		quit: 'Вийти',
		approve:'Схвалити',
		edit:'Редагувати групу',
		empty: 'На даний момент у вас немає жодної робочої групи. Створіть власну групу або зачекайте на запрошення від співробітників',
    no_access: 'Подайте заявку на вступ до групи, щоб дивитися та робити публікації',
    not_found:'Групи не знайдені',
		not_found_one:'Група не знайдена',
		deleted:'Група видалена',
		deleted_gr:'Група {group} була видалена',
		deleting_from: 'Вилучення з групи',
    deleted_you:'Ви були видалені з групи {name}',
		deleted_other: 'Учасник {who} залишив групу "{name}"',
    approving: 'Схвалення запиту',
    rejecting: 'Відхилення запиту',
    approved_you: 'Ваш запит до групи {name} схвалено',
    rejected_you: 'Ваш запит до групи {name} був відхилений',
    select_users:'Виберіть користувачів',
		choose_new_owner:'Виберіть нового власника групи',
		noempls_for_change:'Немає співробітників для заміни',
		owner_changed:'Власника групи змінено',
		make_admin: 'Додати або зняти роль адміністратора',
		new_owner:'Новий власник групи',
		you_owner: 'Ви адміністратор групи',
		is_admin: 'Адміністратор групи',
    you_are_owner: 'Ви стали адміністратором групи {name}',
    owner_error:'Помилка установки власника групи',
		only_1:'У групі всього 1 учасник',
		owner_change_cannot:'Неможливо змінити власника',
		added:'доданий до групи',
		joined: ' вступив до групи ',
		actions:'Дії з групою',
		members:'Учасники',
		q_delete:'Видалити {fio} із групи?',
		confirm_deleting:'Підтвердіть видалення',
		confirm_deleting2:'Підтвердіть видалення групи',
		del_not_found: '<Групу видалено або не знайдено>',
		invite:'Запросити',
		invite_empl:'Запросити користувача',
		invite_sent:'Запрошення надіслано',
		inviting:'Запрошення',
		inviting_group: 'Запрошення до групи',
    you_invited: 'Вас запрошують до групи {name}. Посилання для відправлено на пошту',
    to_group:'до групи',
		set_owner: 'Зробити власником',
    user_will_be_notified:'Користувач отримає повідомлення у стрічці',
		change_owner:'Змінити власника',
		request:'Заявка на вступ до групи',
		requests:'Заявки',
		req_created: 'Заявка на вступ створена',
    req_removed: 'Заявка на вступ видалено',
    request_exists:'Заявка вже подана або ви вже у групі',
		make_request:'Подати заявку',
		cancel_request:'Скасувати заявку',
		request_rejected:'Запит відхилений',
		request_rejected_descr:'Вступ до групи відхилено',
		rejected: 'Запрошення до групи відхилено',
    rejected_full: '{user} відхилив запрошення до групи {group}',
    type:'Тип',
		type_public:'Відкрита',
		type_closed:'Закрита',
		type_private:'Приватна',
		type_public_descr: 'Будь-який користувач може знайти групу через пошук або в загальному списку, переглядати публікації та вступити до спільноти',
		type_closed_descr: 'Будь-який користувач може знайти групу через пошук або у загальному списку. Вступити та переглядати публікації можна тільки на запрошення або після схвалення заявки адміністратором групи',
		type_private_descr: 'Знайти групу у пошуку або у загальному списку можуть лише її учасники. Вступити можна лише на запрошення адміністратора групи.',
		request_made:'Заявка створена',
		request_approved:'Запит схвалений',
		request_approved_descr:'Вступ до групи схвалено',
		request_made_descr:'Заявка на вступ до групи створена',
		made_request:'подав заявку на вступ до групи',
		you_have_invite:'У вас є запрошення до цієї групи. Виберіть дію:',
		you_in: 'Ви вступили до групи',
    new_member: 'Новий учасник групи',
    new_member_full: 'У групі {name} новий учасник: {user}',
		posted_in: 'додав запис у групі ',
    rules: {
			name:'Назва не може бути порожньою!',
			name_max:'Максимальна довжина заголовка - 50 символів',
			descr:'Опис не може бути порожнім!',
			descr_max:'Максимальна довжина опису - 250 символів',
			type:'Тип групи не може бути порожнім!',
		},
		errors: {
			creating: 'Помилка створення групи',
			client:'Помилка перевірки клієнта',
			client_descr:'Не вдалося встановити поточного клієнта',
			data_descr:'Деякі поля заповнені невірно',
			valid:'Помилка при валідації',
			request:'Помилка при затвердженні заявки',
			reject:'Помилка при відхиленні заявки',
		},
    notifs: {
      new_user: 'У групі {group} новий учасник: {userFio}',
      no_access: 'У вас немає доступу до групи {name}'
    },
	},
	// Календар
	cal: {
		title:'Календар',
		year:'Рік',
		month:'Місяць',
		week:'Тиждень',
		day:'День',
		wd: {
			mo:'Пн',
			tu:'Вт',
			we:'Ср',
			th:'Чт',
			fr:'Пт',
			sa:'Сб',
			su:'Нд',
		},
		event:'Подія',
		create:'Створити подію',
		new:'Нова подія',
		ph_name: 'Тижня зустріч топ-менеджерів',
		moving: 'Перенесення події',
		changing:'Зміна події',
		new_cal:'Новий календар',
		not_found: 'Подія не знайдена',
    confirm_delete:'Підтвердіть видалення події',
		no_events_today:'Сьогодні зустрічей немає',
		events_today:'Події сьогодні',
		time_left:'До події',
		go_to_scheduler:'Перейти в календар',
		delete: 'Скасування події',
		del_one: 'Видалити одну',
    del_all: 'Видалити всі',
    del_select: 'Оберіть варіант видалення',
    del_select_full: 'Оберіть, видалити одну чи всі події',
    del: 'Подiя успiшно видалена',
		deleted: 'Подія <b>{name}</b> видалена',
    err_del: 'Помилка при видаленні події',
		cant_create: 'Ви не можете створювати події',
    err_date_lost: 'Неможна створити подію в минулому',
		new_user: 'Вітаємо нового співробітника компанії: ',
		time_changed: 'Час події <b>{name}</b> змінено',
    f: {
			name:'Назва події',
			date:'Дата події',
			date2:'Дата проведення:',
			start:'Початок:',
			end:'Закінчення в:',
		},
		periods: {
			day:'кожен день',
			week:'щотижня',
			month:'кожен місяць',
			year:'щороку',
		},
		author_label: 'Організатор події:',
		description:'Опис',
		date_time: 'Дата та час події',
		start_at:'Дата початку',
		end_at:'Закінчення',
		time_begins:'Час початку',
		time_finish:'Час закінчення',
		event_changed: `Подія <b>{name}</b> перенесена.<br/>
    Старий час - c {fromBegin} за {fromEnd} ({fromDate})<br/>
		Новий час - з {toBegin} до {toEnd} ({toDate})`,
		color:'Колір',
		time_from:'Час з',
		time_to:'по',
		is_repeat:'Подія, що повторюється',
		by_week:'По дням тижня:',
		every:'Кожен:',
		end: 'Закінчується:',
		after:'після',
		one_time:'раз',
		invite:'Запросити інших учасників',
		members:'Учасники події',
		empls: 'Співробітники',
		zoom: 'Посилання на веб-конференцію',
		you_are_participant:'Вас вказали як учасника',
		of_periodical:'регулярної',
		of_event:'події',
		members_pl: 'Жодного | {count} учасник | {count} учасника | {count} учасників',
		rules: {
			name:'Назва не може бути порожньою!',
			name_max:'Максимальна довжина заголовка - 100 символів',
			name_too_short:'Занадто коротка назва, введіть більше 5 символів',
			date:'Потрібно вказати дату',
			date_past:'Дата не може бути в минулому',
			time:'Потрібно вказати час',
			time_lost:'Вибраний час сьогодні вже минув',
			time_err:'Неправильний час закінчення',
			members:'Потрібно обрати учасників',
			url: 'Неправильний формат посилання',
			comment_max: 'Перевищено кількість символів',
			repeat: {
				no:'Не вказано період повторення',
				wdays:'Не вказані дні тижня для повторень',
				end:'Не вказана дата завершення повторень',
				count:'Не вказано кількість повторень події',
				more1:'Вкажіть більше одного повторення',
			},
		},
		notifs: {
			min_10:'До події залишилося менше 10 хвилин',
		},
    copy_link: 'Посилання',
    link_copied: 'Посилання на подію скопійовано',
    link_not_copied: 'Помилка копіювання посилання на подію',
		periods: {
			this_month: 'Цього місяця',
			last_month: 'Минулого місяця',
			this_year: 'Цього року',
			last_year: 'Минулого року',
			this_week: 'Цього тижня',
			last_week: 'Минулого тижня',
		},
	},
	// База знань
	kb: {
		title:'База знань',
		articles: 'Матеріали',
		fav_articles: 'Вибране',
    folders: 'Розділи',
    files: 'Файли',
		amount:'Статей',
		no_art:'Поки нічого немає',
		empty: 'У основі знань немає жодного документа. Поділіться з колегами своїми статтями або чекайте на оновлення розділу',
		folder_empty: 'Ця папка поки що порожня. Поділіться з колегами своїми матеріалами або чекайте на оновлення розділу.',
    new_article:'Нова стаття',
    new_vacancy: 'Нова вакансія',
		new_folder: 'Нова папка в базі знань',
		new_folder_sh: 'Додати папку',
		new_file: 'Додати файл до бази знань',
    new_file_sh: 'Додати файл',
		add_name: 'Додайте назву',
		think_name: 'Придумайте назву',
		ph_descr: 'Розкажіть, яку інформацію можна знайти у Базі знань',
		folder: 'Виберіть папку',
		set_titles: 'Налаштування шапки бази знань',
		change_article: 'Змінити дані статті',
		change_folder: 'Змінити дані папки',
		change_file: 'Змінити дані файлу',
    created_at: 'Дата створення',
    updated_at: 'Дата оновлення',
    search_ph: 'Пошук за базою знань',
		rights: 'Доступи',
		choose_file: 'Вибрати файл',
		attach: 'Прикріпити файл',
    attached_files: 'Прикріплені файли',
		uploading: 'Файл завантажується',
 		drop_media: 'Прикріпити зображення або відео',
    add_cover: 'Додати обкладинку',
    del_cover: 'Видалити обкладинку',
    not_previewable: 'Цей тип файлів неможливо переглянути',
    show_all: 'Показати все',
    hide_off: 'Згорнути',
    add:'Додати статтю',
		placeholder: 'Напишіть якийсь текст...',
		del:'Видалити статтю повністю?',
		del_warn:'Стаття буде видалена без можливості відновлення',
		deleted:'Стаття була видалена',
		saved: 'Стаття збережена',
    folder_saved: 'Папка збережена',
    file_saved: 'Файл збережено',
		copy_link: 'Скопіювати посилання',
		file_link_copied: 'Посилання на файл скопійовано',
    correct:'Виправити статті',
		correctod:'Статті оновлені',
		correctod_cnt:'Оновлено {cnt} статей',
		author:'Автор',
		header: 'Назва',
		toc_title: 'Зміст',
 		toc_empty: 'Додайте до тексту заголовки, щоб побачити зміст',
		grants_article_title: 'Доступи до статті',
		grants_folder_title: 'Доступи до папки',
		grants_file_title: 'Доступи до файлу',
    child:'Дочірні статті',
		no_preview:'Перегляд недоступний',
		preview:'Перегляд файлу',
		pdf: 'Завантажити як PDF',
		fav: 'Избране',
		// to_fav: 'У вибране',
		// from_fav: 'З обраного',
    delete_file: 'Видалити файл?',
		delete_video: 'Видалити завантажене відео?',
    del_file_confirmation: 'Видалення файлу',
    del_folder_confirmation: 'Видалення папки',
    del_article_confirmation: 'Видалення статті',
    del_file_deleting: 'Файл буде видалено назавжди. Продовжити?',
    del_folder_deleting: 'Папку та всі вкладені об\'єкти буде видалено назавжди. Продовжити?',
    del_article_deleting: 'Статтю буде видалено назавжди. Продовжити?',
    delete_files:'Видалити всі файли?',
		files_att:'Вкладені файли',
		filetype_hint: 'Можна додавати документи MS Office, файли .PSD, .CDR, .DWG розміром не більше 100 МБ. Фото та відео можна вставити прямо в тексті статті',
		rights_title:'Права на читання',
		rights_del_hint1:'Права адміністратора будуть видалені, продовжити?',
		rights_del_hint2:'Права всіх співробітників будуть видалені, продовжити?',
		rights_not_set: 'Переглядати можуть усі',
    for_admins:'Для адміністраторів',
		add_empls:'Додати співробітників',
		select_empls:'Виберіть співробітників, які мають право перегляду',
		author_not_found:'Автор не знайдено',
		rights_succ:'Права змінені успішно',
		feedback_sent:'Зворотній зв\'язок відправлено',
    user_will_get_mail:'Користувач отримає повідомлення у пошті',
		change_folder: 'Змінити папку',
    move_folder: 'Перемістити папку',
		no_title: 'Немає заголовка',
		no_subtitle: 'Немає підзаголовка',
		mention_2symbols: 'Введіть мінімум 2 символи',
    err: {
      no_title: 'Не вказано заголовок статті!',
      no_content: 'Немає вмісту статті!',
			no_service_group: 'Не вказано групу сервісів',
      no_section: 'Не вказано розділ!',
      no_access: 'У вас немає доступу до цієї сторінки',
			no_self_replace: 'Не можна переміщати папку до себе або до поточної папки',
			file_not_media: 'Ви намагаєтеся завантажити файл, що не є відео або картинкою',
			no_url :'Немає посилання на файл',
    },
    new: {
      article: 'Нова стаття у базі знань',
      service: 'Нова сторінка сервісу',
      vacancy: 'UX-дизайнер',
    },
    sort: {
      az: 'За алфавітом А-Я',
      za: 'За алфавітом Я-А',
      created: 'За датою створення',
      edited: 'За датою редагування',
      no: 'Без сортування',
    },
		editor: {
			panel: {
				undo: 'Скасувати',
				redo: 'Повернути',
				bold: 'Потовщений',
				italic: 'Похилий',
				underline: 'Підкреслений',
				strike: 'Закреслений',
				code: 'Моноширинний',
				code_block: 'Блок коду',
				del_marks: 'Видалити форматування',
				clear: 'Звичайний рядок',
				link: 'Вставити посилання',
				del_link: 'Видалити посилання',
				emoji: 'Емодзі',
				media: 'Медіа-файл',
				music: 'Яндекс.Музика',
				toc: 'Зміст',
				h1: 'Заголовок 1',
				h2: 'Заголовок 2',
				h3: 'Заголовок 3',
				h4: 'Заголовок 4',
				p: 'Простий текст',
				ul: 'Список',
				ol: 'Нумерований список',
				quote: 'Цитата',
				left: 'Вирівнювання по лівому краю',
				center: 'Вирівнювання по центру',
				right: 'Вирівнювання з правого краю',
				justify: 'Вирівнювання по ширині',
				marker: 'Виділення фону кольором',
				task: 'Пункт завдання',
				table: 'Вставити таблицю',
				t_add_column: 'Додати колонку',
				t_add_column_after: 'Додати колонку після',
				t_add_row: 'Додати рядок',
				t_add_row_after: 'Додати рядок після',
				t_del_col: 'Видалити колонку',
				t_del_row: 'Видалити рядок',
				t_delete: 'Видалити таблицю',
				t_cell_merge: 'З\'єднати комірки',
				t_cell_split: 'Розбити комірку',
				t_header: 'Зробити заголовком перший рядок',
				t_col_header: 'Зробити заголовок-колонку',
			},
		},
  },
	// Вакансії
	vac: {
		title:'Вакансії компанії',
		total:'Всього вакансій',
		new:'Нова вакансія',
		empty: 'На даний момент вакансії відсутні',
    no:'Поки що немає жодної вакансії',
		not_found:'Вакансії не знайдено',
    saved: 'Вакансія збережена',
		apply: 'Рекомендувати/Відгукнутися',
		salary: 'Зарплата (від, до)',
    salary_from: 'від {val}',
    salary_up: ' до {val}',
    no_salary: 'Не вказана',
		response: {
			recievers: 'Отримувачі відгуків',
			recievers_no: 'Одержувачі не вказані',
			no_receivers_hint: 'Не вказано одержувачів відповідей у компанії',
			alert: 'Отримувачі не вказані, відгуки надходитимуть усім адміністраторам',
			sent: 'Вакансію відправлено',
			no_user_to_receive: 'Відгук не надіслано, оскільки не визначено одержувачів',
		},
    deleting: 'Видалення вакансії',
    deleting_descr: 'Вакансія <b>{name}</b> буде видалена. Продовжити?',
    deleted: 'Вакансія видалена',
    deleted_succ: 'Вакансія видалена успішно',
		respond_title: 'Відгук на вакансію',
    to_feed: 'У стрічці подій',
		feed_post: 'Шановні колеги! <br/>У нашій компанії відкрита вакансія <a href="/vacancy/{id}" target="_blank">{header}</a><br/>',
    share: 'Копіювати посилання',
    vac_posted: 'Вакансія опублікована у стрічці',
    link_copied: 'Посилання на вакансію скопійоване',
    respond: {
      field1_label: 'Ім\'я відправника',
      field2_label: 'Текст супровідного листа',
      att_cv: 'Додати резюме',
      no_admins: 'Не знайдено жодного адміністратора',
    },
		template: `<h5>Опис вакансії</h5><br/>

		<h5>Вимоги до кандидата</h5><br/>
		
		<h5>Умови</h5><br/>
		`,
	},
	// Сервіси
	srv: {
		tabs: {
			all: 'Сервіси',
			my: 'Мої заявки',
			info: 'Інформація',
			steps: 'Кроки',
			rights: 'Права',
			table: 'Заявки',
		},
		bp_table: {
			name: 'Назва процесу',
			author: 'Автор',
			performer: 'Виконавець',
			chief: 'Керівник',
			start: 'Дата початку',
			step: 'Етап',
			deadline_step: 'Дедлайн етапу',
			deadline: 'Загальний дедлайн',
			status: 'Статус',
			active: 'Активні',
 			finished: 'Завершені',
			result: 'Статус',
		},
		list: 'Бізнес-процеси',
		my_orders: 'Мої заявки',
		orders_to_me: 'Заявки для узгодження',
		open_srv: 'Відкрити заявку',
    title:'Сервіси для співробітників',
		total:'Всього сервісів',
		groups:'Групи сервісів',
		convert: 'Перетворити старі',
 		new: 'Новий бізнес-процес',
		empty: 'Тут будуть корпоративні послуги вашої компанії',
    new_bp:'Нова сторінка сервісу',
		edit: 'Редагувати сервіс',
    edit_bp: 'Зміна бізнес-процесу',
		confirm_del: 'Видалити бізнес-процес?',
		types: {
			label: 'Тип сервісу',
			simple: 'Простий',
			simple_d: 'З одним етапом',
			many: 'Многоетапний',
			many_d: 'З кількома етапами',
			},
		dept: 'Відповідальний підрозділ',
		no_dept: 'Підрозділ не вказано',
		no_user: 'Співробітник не вказано',
		no_date: 'Дата не вказана',
		no_data: 'Поле не заповнене',
		days_limit_set: 'Встановити термін виконання',
		days_limit: 'Термін виконання',
		no_limit: 'Термін не встановлено',
		for_all: 'Доступний всім',
		for_all_descr: 'Кожен може користуватися цим процесом',
    new_group:'Новий розділ',
		new_group_add: 'Додати групу сервісів',
    no:'Поки що немає жодного сервісу',
		not_found:'Сервіси не знайдені',
		deleting: 'Видалення сервiсу',
    deleting_descr: 'Сервiс «{name}» буде видалено',
    replace:'Перемістити до іншого розділу',
		change:'Змінити розділи сервісів',
		no_groups:'Розділів немає',
		add_group:'Додати розділ',
		del_group:'Видалити розділ',
		group_deleted:'Розділ видалений',
    group_name: 'Назва групи',
		name: 'Назва сервісу',
		group: 'Група сервісу',
		body: 'Опис сервісу',
    new_group_ph: 'Наприклад: Довідки',
    name_ph: 'Наприклад: Довідки ПДФО',
		sg_empty_title: 'Немає групи сервісів',
    sg_modal_title: 'Нова група сервісів',
    sg_modal_edit: 'Зміна групи сервісів',
    saved: 'Розділ збережен',
    select_group: 'Оберіть розділ сервісу',
		select_empl: 'Виберіть співробітника',
		step_employee: 'Відповідальний етап',
		fields: 'Поля процесу',
		steps: {
			start: 'Створення заявки',
			name: 'Назва кроку',
			fields: 'Поля кроку',
			fill_fields: 'Заповніть поля',
			new: 'Новий крок',
			review: 'Ознайомлення',
 			review_btn: 'Ознайомився',
			new_field: 'Додати поле',
			field_title: 'Заголовок поля',
			field_value: 'Введіть значення',
			field_select: 'Виберіть значення',
			empty: 'Поки немає кроків',
			errors: 'Помилки в налаштуваннях кроку',
			performer_settings: 'Налаштування відповідального на етапі',
			performers: 'Виконавці етапу',
			no_performers: 'Не вказано виконавців етапу',
			initiator: 'Ініціатор заявки',
			chief: 'Безпосередній керівник',
			employee: 'Співробітник',
			employees: 'Кілька співробітників',
			set_me: 'Взяти на себе',
 			set_me_hint: 'Встановити себе як єдиного виконавця та погодити пізніше',
			set_alone_succ: 'Тепер ви єдиний виконавець етапу',
 			no_more5_empls: 'Не можна вибирати більше 5 співробітників',
			not_more10: 'Не можна створити більше 10 полів на формі',
			no_fields: 'У формі немає жодного поля',
			no_fieldname: 'Не вказано назву одного з полів',
			no_formname: 'Не вказано назву форми',
			no_receivers: 'Не вказано одержувачів даних',
			default_name: 'Дані для заповнення',
 			no_selected: 'Не вибрано крок',
			save: 'Зберегти крок',
			del: 'Видалити крок',
			deleting: 'Видалення кроку',
			deleting_descr: 'Крок "{name}" буде видалений',
			deleted: 'Крок видалений',
			deleted_succ: 'Крок видалено успішно',
			not_more5: 'Не можна створити більше 5 кроків',
			saved: 'Крок збережений',
			saved_descr: 'Ці кроки успішно збережені',
			open_progress: 'Показати кроки',
			curr_step: 'Поточний крок',
			fill_fields: 'Заповніть поля та затвердіть або відхиліть поточний етап заявки',
			accept: 'Узгодити',
			decline: 'Відхилити',
			enter_reason: 'Вкажіть причину відхилення',
			invalid_action: 'Неправильна дія',
			decline_reason: 'Причина відхилення не вказана',
			accepted_succ: 'Крок узгоджений',
			declined_succ: 'Крок відхилений',
			rejected: 'Крок відхилений через:',
			field_types: {
				string: 'Рядок',
				text: 'Довгий текст',
				number: 'Число',
				date: 'Дата',
				період: 'Період дат',
				select: 'Вибір зі списку',
				checkbox: 'Опція "Галка"',
				file: 'Файл',
				dept: 'Підрозділ',
				user: 'Вибір співробітника',
				users: 'Вибір співробітників',
				phone: 'Телефон',
				email: 'Пошта'
			},
		},
		form: {
			form: 'форма',
      title:'Назва форми',
			descr: 'Опис форми',
      new:'Нова форма',
			new_srv:'Нова форма сервісу',
			no_forms: 'Поки що тут порожньо',
      edit:'Змінити форму',
			fill:'Заповнити',
			errors:'Помилки при заповненні форми',
			srv_form:'Сервісна форма {name}',
			new_field:'Додати поле',
			field_title:'Заголовок поля',
			field_type:'Тип поля',
			field_mng:'Управління',
			field_simple:'Просте поле',
			field_long:'Довгий текст',
			field_option:'Опція (галка)',
			preview:'Перегляд форми',
			this_user: 'Автор звернення',
      max_str:'Введено максимально довгий рядок (<50 символів)',
		},
		err: {
			no_descr: 'Не заповнено опис процесу',
			no_name: 'Не вказано назву процесу',
			no_group: 'Не вибрано розділ сервісу',
			chief_404: 'Не вдалося визначити керівника',
			del_error: 'Розділ містить послуги. Видалити можна лише порожній розділ',
		},
		start_bp: 'Створити заявку',
		cant_start: 'Не можна створити заявку, потрібно мінімум 2 кроки',
		start_title: 'Створення нової заявки',
		bp_started: 'Заявка створена',
		bp_start_error: 'Не вдалося створити заявку',
		del_error: 'Не вдалося видалити групу',
		succ: 'Успішно завершено',
 		rejected: 'Відхилена',
		 review: 'Ознайомтесь будь ласка',
	},
	// Опитування
	polls: {
		title:'Опитування',
		new:'Наприклад, опитування залучення',
		empty: 'Доступних опитувань поки що немає, але ви можете створити опитування, щоб оперативно зібрати думки ваших колег',
    create:'Створити опитування',
		name: 'Додайте назву опитування',
		descr:'Введіть опис питання, яке побачать співробітники',
		enter_descr:'Наприклад: Нам важлива, ваша думка. Допоможіть нам зробити компанію ще кращою!',
		deleting:'Видалення опитування',
		delete:'Видалити опитування «{name}»?',
		deleted:'Опитування видалено',
		q_amount:'Питань в опитуванні',
		q_short: 'пит.',
		you_can:'Ви можете пройти опитування',
		enter_poll:'Перейти до опитування',
		description:'Опис',
		ph_name: 'Введіть назву опитування',
		ph_descr: 'Додайте опис опитування',
		editing: 'Редагування',
		types: {
			opened: 'Відкритий',
			opened_descr: 'Cпівробітники бачать обрані відповіді один одного',
      opened_full: 'Це опитування відкрите, всі можуть бачити відповіді інших учасників',
      closed: 'Закритий',
			closed_descr: 'Співробітники бачать лише свої відповіді, адміністратори бачать усі дані',
			closed_full: 'Відповіді опитування закриті, ви можете бачити лише свої</b>. Адміністратор може бачити усі відповіді.',
      anonymous: 'Анонімний',
			anonymous_descr: 'Співробітники бачать лише свої відповіді, адміністратор бачить знеособлені відповіді',
			anonymous_full: 'Дане опитування є <b>анонімним</b>, ніхто не бачить персональних відповідей',
		},
    post_msg_created: 'Створено нове опитування',
		tbl: {
			main: {
				name:'Назва опитування',
				anonymous: 'Анонімний',
				author:'Автор',
        created:'Дата створення',
				type: 'Тип опитування',
				questions:'Питання',
				answered:'Відповідів',
        actions: 'Дії',
			},
		},
		qs:'Питання',
		q_n:'Питання {n}',
		add_q: 'Додати питання',
    mandatory_q: 'Обов\'язкове питання',
    delete_conf: 'Питання буде видалено, ви впевнені?',
    text_q: 'Текст питання',
    text_ph: 'Наприклад, чи знаєте ви, чого від вас очікує працедавець?',
    kind_q: 'Різновид питання',
    answer:'Відповідь',
		answer_n:'Відповідь {n}',
		answers:'Відповіді',
		my_answer: 'Моя відповідь',
		no_answer: 'Нема відповіді',
		choose_range:'Виберіть значення від 1 до {range}',
		you_choose:'Ви обрали: {val}',
		no_variants:'Немає варіантів',
		add_ans:'Додати варіант відповіді',
		range:'Діапазон оцінки',
		loading_results: 'Завантажуємо результати',
    nothing:'нічого',
		r_1_5:'від 1 до 5',
		r_1_10:'від 1 до 10',
		r_1_100:'від 1 до 100',
		score:'Оцінка',
		ans_types: {
			short:'Коротка відповідь текстом',
			long:'Розгорнута відповідь текстом',
			range:'Вибір числа в діапазоні',
			list1:'Один варіант зі списку',
			list_m:'Кілька варіантів зі списку',
		},
		export:'Завантажити в Excel',
		passed:'Опитування пройшли',
		finished: 'Співробітник пройшов опитування',
		end:'Завершити',
		next: 'Наступне питання',
		back: 'Попереднє питання',
		progress: 'Ви відповіли на {num} питань з {count}',
		your_results: 'Ваші результати',
		no_results: 'Результатів поки що немає',
    results:'Результати',
		result_tabs: {
			main: 'Загальні',
			table: 'Таблицею',
			not_passed: 'Не пройшли опитування',
		},
		result_table: {
			name: 'ПІБ',
			position: 'Посада',
			dept: 'Відділ',
			isChief: 'Керівник',
			city: 'Місто',
			gender: 'Пол',
			workingTime: 'Час роботи',
		},
		filters: {
			gender: 'Пол',
			ages: 'Вік',
			depts: 'Відділ',
			cities: 'Місто',
			workingTimes: 'Час роботи',
			period: 'Період проходження',
			chief: 'Керівник',
			positions: 'Посада',
			active: 'Активні фільтри',
			execStart: 'Пройдено після',
			execFin: 'Пройдено до',
			ageOptions: {
				lt20: 'До 20 років',
				lt30: '20-30 років',
				lt40: '30-40 років',
				lt50: '40-50 років',
				gt50: '50+ років'
			  },
			  chiefOptions: {
				isChief: 'Керівник',
				isNotChief: 'Не керівник',
			  },
			  timesOptions: {
				lt3m: 'Менше 3 місяців',
				lt1y: '3 місяці - 1 рік',
				lt5y: '1-5 років',
				lt10y: '5-10 років',
				gt10y: '10+ років',
			  },
		},
		questions_num: 'Питань',
		passed_num: 'Пройшли опитування',
		date: 'Дата створення',
		series: {
			amount: 'Кількість',
		},
		result_hint: '{num} відповідей або {percent}%',
		del_filter: 'Скинути фільтр',
		avg:'Середній результат',
		err_excel:'Помилка отримання Excel файлу',
		err_name:'Не вказано назву',
		have_passed:'Ви вже проходили це опитування',
		is_passed: 'Пройдено',
		take:'Пройти опитування',
		view_results:'Переглянути результати',
		loading:'Опитування завантажується',
		grants_article_title: 'Доступи до статті',
		empl_label:'Вибрати співробітників для опитування',
		empl_select:'Виберіть співробітників',
		grants_title: 'Доступи до опитування',
		for_all:'Для всіх співробітників',
		closed:'Закриті результати',
		anon_opt:'Опитування анонімне',
		actions:'Дії з опитуванням',
		no_data:'Немає даних щодо опитування',
		saved_new:'Нове опитування збережено',
		saved:'Опитування збережено',
		thanks: 'Дякуємо за проходження опитування!',
    return: 'Повернутись до опитувань',
    see_results: 'Результати ви можете побачити, якщо тип опитування Не Закрите',
    answers_title: 'Відповіді співробітників на запитання',
    answered_users: 'Співробітники, які вказали варіант відповіді',
		who_answered: 'На питання <b>«{question}»</b> співробітники відповіли:',
    answer_choosen: 'Варіант відповіді <b>«{question}»</b> вибрали:',
    errors: {
			access: 'У вас немає доступу до цього опитування',
 			valid: 'Помилки перевірки опитування',
			no_name: 'Не заповнено назву опитування',
      no_more15ans:'Не можна додати більше 15 відповідей!',
			nothing:'Не введено жодного питання, збереження неможливе',
			not_all:'Не всі запитання заповнені',
			no_mandatory:'Не вказано хоча б одне обов\'язкове питання',
			no_ans:'У деяких питаннях з варіантами відповідей не зазначено жодної відповіді',
			ans_not_filled:'У деяких запитань є незаповнені відповіді',
      excel: 'Помилка при отриманні файлу Excel',
      no_recipients: 'Не вибрано одержувачів закритого опитування'
    },
	},
	// Чати
	chat: {
		me: 'Я',
		no_rooms:'Немає жодної кімнати',
		no_room_code:'Не заданий код кімнати',
		create_q:'Створити кімнату?',
		no_msg:'Поки немає жодного повідомлення',
		enter_name:'Введіть назву чату',
		create_private:'Створити особистий чат',
		room_added:'Кімната додана',
		room_deleted:'Кімната видалена: {room}',
		del_room: 'Видалити чат',
    members:'Учасників: {len}',
		error_rooms:'Помилка при отриманні кімнат',
		error_add_room:'Помилка при додаванні кімнати',
		error_del:'Помилка видалення',
		with:'Чат з:',
		add_empl: 'Додати співробітника',
    del_empl: 'Видалити співробітника',
    delete_all:'Видалити чат і всі повідомлення?',
		delete_room:'Кімната та всі повідомлення будуть видалені',
		you_added: 'Ви додані до кімнати чату',
    you_removed:'Ви видалені з кімнати: {name}',
		private_started: 'З вами розпочав особистий чат {with}',
    new_msg:'Нове повідомлення групи {name}',
	},
	// Структура
	struct: {
		title:'Організаційні структури',
		adm:'Адміністративна',
		org: 'Організаційна',
		func:'Функціональна',
		project:'Проектна',
		main_org:'Головне управління',
		main_func:'Головний підрозділ',
		main_prj:'Головний проект',
		choose_type: 'Вид структури компанії',
		hide_types: 'Приховати структури',
		expand_all: 'Розгортати повністю',
		t_diagram: 'У вигляді діаграми',
		t_tree: 'У вигляді списку-дерева',
		select_dept:'Виберіть відділ переносу',
		select_dept_add: 'Виберіть відділ для додавання',
		no_empl:'Немає співробітників',
		add_empl: 'Додати співробітника',
		new_name:'Вкажіть нову назву',
		new_empl:'Додати співробітників',
		sel_mng:'Вибрати керівника',
		del_empl:'Видалити співробітника',
		add_dept:'Додати відділ',
		move_dept: 'Перемістити відділ',
		pin_dept: 'Закріпити відділ',
		unpin_dept: 'Відкріпити відділ',
		change_info:'Змінити дані',
		no_descr: 'Немає опису відділу',
    replace_dept:'Перенести відділ',
		del_dept:'Видалити відділ',
		error_load:'Не вдалося завантажити дані про структуру',
		empl_added:'Додатковий співробітник',
		empl_deleted:'Співробітник видалено',
		empl_not_deleted:'Співробітника не видалено',
		empl_deleted_full:'{name} видалено з відділу',
		empl_added_full:'Співробітник(и) доданий(и) до відділу',
		empl_not_added:'Співробітник не додано',
		del_empl_confirm:'Підтвердіть видалення працівника',
		del_empl_content:'Співробітник «{name}» буде видалено',
		err_no_company:'Не задана поточна компанія',
		mngr_set:'Керівник встановлений',
		no_manager: 'Керівника не призначено',
    mngr_set_full:'Начальник відділу відтепер {name}',
		err_manager:'Помилка вибору керівника',
		saved:'Структура збережена',
		err_save:'Помилка збереження',
		err_not_found: 'Не знайдено відділу',
		err_no_empl:'У даному відділі немає співробітників',
		add_empl_to_dept: 'Додати працівника до відділу',
		err_empl_in_dept:'У відділі є співробітники ({len})',
		err_dept_delete:'Помилка видалення відділу',
		err_root_dept:'Головний відділ не можна видалити',
		dept_del_confirm:'Підтвердіть видалення відділу',
		dept_del_content: 'Відділ\'{name}\'буде видалений',
		find_by_fullname:'Пошук з ПІБ',
		title_choose:'Виберіть нових співробітників',
		title_del:'Виберіть співробітника для видалення',
		title_mngr:'Виберіть начальника відділу, натиснувши на іконку в списку',
		title_new_dept:'Створення нового підрозділу',
		title_edit_dept: 'Зміна підрозділу',
		upper_dept:'Вищий підрозділ',
		please_name:'Будь ласка, введіть назву відділу',
		ph_name: 'Відділ маркетингу',
		ph_descr: 'Допомагаємо потенційним клієнтам дізнатися про наш продукт і стати амбасадорами бренду',
		employees: 'Співробітники',
		manager: 'Керівник',
		managers: 'Керівникi',
		employee: '{n} співробітників | {n} співробітник | {n} співробітники | {n} співробітників',
		employees_of: 'співробітників',
		open_list: 'Розкрити перелік',
		in_the_dept: 'в цьому відділі',
 		include_sub: 'включаючи всі підлеглі',
		err: {
			move_same: 'Відділ збігається з поточним',
			move_child: 'Не можна перемістити до дочірнього відділу',
		},
	},
	// Не мовчи
	quiet: {
		title:'Не мовчи',
		user_mode: 'Режим відправлення',
    admin_mode: 'Режим редагування',
    recipient: 'Призначте співробітників для розгляду звернень',
		list_header: 'Список співробітників, які отримують звернення',
    what:'Що таке «Не мовчи»',
		no_service: 'Сервіс поки недоступний...',
    no_srv_descr: 'Тут ви зможете повідомити про будь-яке правопорушення чи зловживання співробітників. Адміністратор скоро надасть доступ до сервісу',
    recipients: 'Звернення розгляне',
		descr: 'Це конфіденційний канал, в якому ви можете повідомити про будь-яке правопорушення або зловживання співробітника: хабар, “відкат”, конфлікт інтересів, негідну поведінку',
		enter_name:'Введіть своє ім\'я',
		label_name:'Автор звернення',
		anon:'Анонімно',
		problem_label: 'Опишіть суть скарги',
    problem:'До мене дійшли відомості, що...',
		name:'Ім\'я',
		empls:'Співробітники, які отримують звернення',
		sent:'Повідомлення надіслано',
		sent_full:'Вашого листа надіслано. Його розглянуть найближчим часом. Дякуємо Вам за пильність!',
		rules: {
			name: 'Представтеся або зробіть звернення анонімним',
      trouble: 'Додати докладний опис вашого звернення',
		},
	},
	// Є ідея
	idea: {
		title:'Запропонувати ідею',
		recipient: 'Призначте співробітників для розгляду пропозицій',
		recipients: 'Ідею розгляне',
    no_service: 'Сервіс поки недоступний...',
    no_srv_descr: 'У цьому сервісі ви зможете пропонувати ідеї для покращення процесів у компанії. Адміністратор скоро надасть доступ до сервісу',
    list_header: 'Список співробітників, які отримують пропозиції',
    descr: 'Тут ваші співробітники можуть запропонувати ідеї для покращення процесів усередині компанії. Призначте співробітників для розгляду пропозицій',
    fio:'Введіть ім\'я та прізвище',
		empls:'Співробітники, які отримують звернення',
		no_receivers: 'Виберіть одержувачів звернень',
    fields: {
      name: {
        label: 'Автор ідеї',
        hint: 'Прізвище та ім\'я',
        error: 'Потрібно вказати свої дані',
      },
      describe: {
        label: 'Опишіть поточний процес',
        hint: 'Хочу поділитися своїми думками щодо...',
        error: 'Потрібно вказати який процес треба покращити',
      },
      disadv: {
        label: 'Поліпшення',
        hint: 'Я вважаю, що можна покращити...',
        error: 'Потрібно вказати недолік',
      },
      changes: {
        label: 'Зміни',
        hint: 'Я пропоную змінити...',
        error: 'Потрібно описати, що потрібно зробити',
      },
      results: {
        label: 'Який результат можуть дати зміни?',
        hint: 'Пропоновані мною зміни наведуть...',
        error: 'Опишіть передбачуваний результат змін',
      },
    },
    got: 'Готово!',
    got_full: 'Ваша пропозиція надіслана. Дякую,<br/> що допомагаєте робити нашу спільну справу краще!',
    sent:'Пропозиція надіслана',
		sent_descr:'Ваша пропозиція надіслана адміністратору на пошту',
	},
	// Співробітники
	empls: {
		title:'Управління співробітниками',
		admin:'Адміністратор',
		empl:'Співробітник',
		mng_tab:'Усі співробітники ({n})',
		rights_tab:'Права та ролі',
		stat_tab:'Статистика настрою',
		other_tab: 'Інші налаштування',
		notif_tab: 'Повідомлення',
    ui_tab: 'Інтерфейс',
		company_info_tab: 'Дані компанії',
		f: {
			name:'Ім\'я',
			mname:'По батькові',
			lname:'Прізвище',
			bd:'Дата народження',
			phone:'Телефон',
			lang:'Мова',
			int_phone:'Внутрішній телефон',
			email:'Ел. пошта',
			position:'Посада',
			cabinet: 'Кабiнет',
			department: 'Підрозділ',
			workingSince: 'У компанії з',
			pwd:'Пароль',
			pwd2:'Підтвердження пароля',
		},
		about: 'Про себе',
		about2: 'Інформація про себе',
		soc_nets: 'Соціальні мережі',
		for_hr: 'Кадрові документи',
		hr: 'Інформація для HR',
		hr_files: 'Файли для HR',
		hrm_data_title: 'Дані із ЗУП',
		show_del:'Показувати віддалені',
		new:'Новий співробітник',
		greet_new:'Вітаємо нового користувача',
		written:'Співробітник записаний',
		saved:'Співробітник {fio} збережено',
		created:'Співробітник {fio} створено',
		total:'Всього співробітників',
		incl_del:'(включаючи %{len} віддалені)',
		sent:'Листів надіслано за',
		not_sent:'Листи ще не надсилалися',
		pwd_reset:'Скинути пароль',
		reset_by_admin:'Скидання пароля (адміністратором)',
		reset:'Скинути',
		change:'Зміна даних співробітника',
		enter_name:'Введіть ім\'я співробітника',
		choose_bd:'Виберіть дату',
		enter_pwd:'Введіть пароль',
		enter_mname:'Введіть по батькові співробітника',
		enter_lname:'Введіть прізвище співробітника',
		enter_email:'Введіть e-mail',
		enter_position: 'Введіть посаду',
		enter_lang:'Виберіть мову',
		will_del: 'буде видалено',
		fired: 'Звільнений',
		fireds: 'Звільнені',
    confirm_del:'Підтвердіть видалення працівника',
		list_del:'Видалення зі списку співробітників відділів',
		exclude:'Видалення зі складу учасників груп',
		blocked:'Вхід на портал буде заблоковано',
		pwd_admin_change: 'У користувача <b>{user}</b> буде встановлено новий пароль',
		enter_new_pwd:'Введіть новий пароль',
		repeat_pwd:'Повторіть пароль',
		upload_users: 'Завантажити співробітників списком',
 		download_users: 'Завантажити список співробітників',
		export_sendsay: 'Оновити e-mail адресу',
		export_sendsay1:'Вивантажити в Sendsay',
		export_process: 'Співробітники вивантажуються в SendSay...',
		export_finish: 'Вивантаження закінчено..',
		export_err: 'Вивантаження завершилося з помилкою: {err}',
		add_users: 'Заповніть список співробітників для додавання',
		get_excel: 'Завантажити шаблон Excel',
 		select_excel: 'Завантажити з Excel',
		has_email_repeats: 'Є повторювані e-mail адреси',
		user_exists: 'Користувач з таким e-mail вже існує',
		success: 'Співробітник успішно зареєстрований',
		tbl: {
			main: {
				fio:'ПІБ',
				email:'e-mail',
				positions:'Посада/и',
				phone:'Телефон',
				is_admin:'Роль',
				actions:'Дії',
			},
		},
		rights: {
			wall:'Публікації на стіні',
			groups:'Використання груп',
			calendar:'Використання календаря',
			profile_edit: 'Редагування профілю',
			knowl_base:'Використання бази знань',
			events_create: 'Створення подій',
			task_tracker: 'Таск-трекер',
			desk_add: 'Додавання дощок завдань',
			vacancies:'Використання вакансій',
			services:'Використання сервісів',
			structures:'Використання орг. структур',
			no_silent:'Використання функції "Не мовчи"',
			ideas:'Використання функції "Запропонувати ідею"',
			kb_files:'Створювати матеріали на базі знань',
			vacations: 'Доступ до відпусток',
			vacations_edit: 'Керувати відпустками',
		},
		rights_change: 'Зміна прав',
		rights_add: 'Вам додані права',
		rights_rem: 'У вас знято права',
		rights_chng: {
			wall: "на публікації на стіні",
			groups: "на використання груп",
			profile_edit: 'на редагування профілю',
			calendar: "на використання календаря",
			events_create: 'додавання подій',
			task_tracker: 'на таск-трекер',
			desk_add: 'на додавання дощок завдань',
			knowl_base: "на використання бази знань",
			kb_files: "на додавання матеріалів до бази знань",
			vacancies: "на використання вакансій",
			services: "на використання сервісів",
			structures: "на використання орг. структур",
			no_silent: "на використання функції 'Не мовчи'",
			ideas: "на використання функції 'Запропонувати ідею'",
			polls: 'на використання опитувань',
			admin: "адміністратора",
			chat: "на чати",
			shop: 'на доступ до магазину товарів',
			adaptation: 'на доступ до адаптації',
			vacations: 'на доступ до відпусток',
			vacations_edit: 'на зміну відпусток',
		},
		funcs_on: 'Включена можливість:',
		funcs_off: 'Вимкнена можливість:',
		funcs: {
			wall: "Публікації на стіні",
			groups: "Використання груп",
			profile_edit: 'Редагування профілю',
			calendar: "Використання календаря",
			events_create: 'Створення подій',
			task_tracker: 'Таск-трекер',
			desk_add: 'Додавання дощок завдань',
			knowl_base: "Використання бази знань",
			kb_files: "Додавання матеріалів у базі знань",
			vacancies: "Використання вакансій",
			services: "Використання сервісів",
			structures: "Використання орг. структур",
			no_silent: "Використання функції 'Не мовчи'",
			ideas: "Використання функції 'Запропонувати ідею'",
			polls: 'Опитування',
			chat: "Чати",
			shop: 'Магазин',
			adaptation: 'Адаптація',
			vacations: 'Використання відпусток',
			vacations_edit: 'Зміна відпусток',
		},
		err: {
			del:'Помилка при видаленні співробітника',
			del_admin:'Неможливо видалити адміністратора',
			del_self:'Неможливо видалити себе',
			save:'Помилка збереження співробітника',
			email: 'Співробітник з таким емейлом вже зареєстрований',
			role_set:'Помилка призначення ролі',
			role_del:'Помилка видалення ролі',
			role_change:'Помилка зміни ролі',
			own:'Не можна змінювати свою роль',
			data:'Помилка даних',
			not_found:'Співробітник не знайдено',
 			export: 'Помилка експорту до SendSay',
		},
		rules: {
			name:'Необхідно вказати ім\'я',
			last_name:'Необхідно вказати прізвище',
			symbols:'Неприпустимі символи в рядку',
			email:'Необхідно вказати e-mail',
			corr_email:'Вкажіть коректний e-mail',
			pwd:'Вкажіть пароль',
			pwd_len: 'Пароль має бути не менше 6 символів',
			pwd_confirm: 'Необхідно підтвердити пароль',
			pwd2:'Паролі не збігаються',
			max20:'Максимум 20 символів',
		},
		restore_del:'Відновити видаленого співробітника',
		empl_will_restore:'Співробітника «{name}» буде відновлено на порталі',
		role_change:'Зміна ролі',
		role_changed: 'Роль змінена',
    role_add_to_all:'Додати роль усім співробітникам?',
		role_rem_from_all:'Зняти роль з усіх працівників?',
		role_succ:'Роль усім присвоєна успішно',
		role_deleted:'Роль видалена у всіх успішно',
		remove_adm:'Зняти з {fio} права адміністратора',
		make_adm:'Зробити співробітника {fio} адміністратором?',
		deleted:'видалений',
		deleting:'видалення',
		restored:'відновлено',
		restoring:'відновлюємо',
		replaced:'Заміна в групах',
		del_as_admin:'Видалення як начальника',
		empls_was:'Співробітник {fio} був {del_type}',
		mood_period:'Період аналізу',
		two_weeks:'Два тижні',
		one_week:'Тиждень',
		no_features:'У клієнта немає доступних функцій, для яких призначаються ролі',
		features: {
			wall:'Стіна',
			address:'Адресна книга',
			groups:'Групи',
			calendar:'Календар',
			profile_edit: 'Редагування профілю',
			knowl_base:'База знань',
			events_create: 'Створення подій',
			task_tracker: 'Таск-трекер',
			desk_add: 'Додавання дощок завдань',
			vacancies:'Вакансії',
			services:'Сервіси',
			structures:'Орг. структури',
			no_silent:'Не мовчи',
			polls:'Опитування',
			ideas:'Запропонувати ідею',
			kb_files:'Редактор бази знань',
			chat:'Чати',
			shop:'Магазин',
			vacations: 'Канікули',
			vacations_edit: 'Зміна відпусток',
			dark_theme: 'Темна тема',
		},
		forbid:'Заборонити',
		allow:'Дозволити',
		confirmation:'Підтверджено',
		role_was_added:'Роль додана успішно',
		role_was_removed:'Роль знята успішно',
		role_was_all_add:'Роль присвоєна всім успішно',
		role_was_all_rem:'Роль знята у всіх успішно',
		notif_settings: 'Канали оповіщень компанії',
    notif_empl_settings: 'Активність каналів оповіщення співробітника',
		tmpls: {
			code: 'Код шаблону',
			name: 'Назва',
			title: 'Заголовок',
			body: 'Текст короткого шаблону',
			full: 'Текст повного шаблону',
			hint: 'Змінні',
		}
	},
	admin: {
		company_settings: 'Налаштування компанії',
		logo_link: 'Посилання на логотип компанії',
		start_page: 'Стартова сторінка при вході',
		hrm_link: 'Адреса бази ЗУП',
		load_empls: 'Завантажити співробітників',
		empls_replace: 'Оновити дані у профілі',
		empls_replace_warn: 'Увага! Дані будуть перезаписані даними з 1С',
		load_struct: 'Завантажити структуру компанії',
		load_hrm_ok: 'Завантаження завершено успішно',
		load_hrm_info: 'Завантажено: {ok}, створено {cr_len} нових{created}',
		load_hrm_noemails: '. У ЗУП знайдено {no_emails} співробітників без e-mail, заповніть та повторіть завантаження',
		load_struct_hrm_ok: 'Структура компанії завантажена',
		other: 'Інші налаштування',
		info: {
			inn: 'ІНН',
			inn_ph: 'Введіть 10 цифр',
			account: 'Розрахунковий рахунок',
			address: 'Юридична адреса',
			phone: 'Телефон компанії'
		},
		errors: {
			inn: 'Некоректний формат ІПН',
			no_inn: 'Для інтеграції з 1С необхідно заповнити ІПН у даних про компанію',
		},
		ui: {
			show_surnames: 'Показувати по-батькові в адресній книзі, структурі компанії та профілі співробітника',
			show_client_title: 'Показувати назву організації в шапці порталу',
			article_files_list: 'Показувати файли в статтях як список',
			exportUserHRMData: 'Вивантажувати користувачів із даними із HRM',
			editor_type: 'використовувати легкий редактор',
			show_mood: 'Сховати за замовчуванням опитування настрою.',
			links: {
				head: 'Корисні посилання',
				title: 'Видимість блоків з посиланнями',
				edit_title: 'Редагування посилання',
				name_ph: 'Опишіть посилання',
				link_ph: 'Введіть посилання',
				article: 'Стаття',
				select_article: 'Виберіть статтю',
				ext: 'Зовнішнє посилання',
				isBase: 'База знань',
				saved: 'Блок посилань збережений',
				no_links: 'Немає жодного посилання',
				del_confirm: 'Точно видалити посилання?',
				err: {
					title: 'Не вказано заголовок',
					link: 'Не вказано посилання',
					link_url: 'Некоректне посилання',
				},
			},
			colors: 'Усі кольори'
		},
	},
	// Клієнти
	clients: {
		title:'Керування клієнтами',
		new:'Новий клієнт',
		total:'Всього клієнтів',
		hide:'Приховати заблокованих',
		active:'Активний',
		block:'Блокування',
		will_clon: 'Буде клонований клієнт з ID',
		clone:'Клонування клієнта',
		del_client:'Видалити клієнта',
		tbl: {
			main: {
				name:'Назва, код',
				phone:'Телефон',
				address:'Адреса компанії',
				users:'Кількість співробітників',
				access_status:'Статус',
				enter:'Вхід',
			},
		},
		err: {
			no_ws:'Не вказано ім\'я воркспейсу',
			no_name:'Не вказано нову назву',
			import_err: 'Не вдалося імпортувати дані клієнта',
		},
		import: 'Імпорт з бекапу',
 		imported: 'Клієнт імпортований успішно',
		new_ws_name:'Нове ім\'я воркспейсу',
		new_name:'Нова назва клієнта',
		full_deleting:'Повне видалення клієнта',
		del_disclaimer:'Клієнт буде видалений разом зі своїми даними (пости, статті тощо). \nСкасувати цю операцію неможливо. \nПідтвердіть цю дію, поставивши галочку в полі згоди',
		del_confirm:'Підтвердити повне видалення даних клієнта',
		sendsay_export: 'Вивантаження уSendSay',
    export_ok: 'Вивантаження у SendSay завершено',
    export_err: 'Вивантаження у SendSay не вдалася: {err}',
    tabs: {
      data: 'Дані',
      design: 'Дизайн',
      features: 'Функціонал',
      other: 'Інші доступи',
			services: 'Обслуговування',
    },
    name: 'Повна назва',
    name_ph: 'Введіть повну назву',
    code: 'Код (workspace) клієнта',
    code_ph: 'Введіть короткий код',
    access: 'Доступ до системи',
    city: 'Місто',
    city_ph: 'Введіть місто',
    phone: 'Телефон',
    address: 'Адреса',
    address_ph: 'Введіть адресу',
    director: 'Директор',
    director_ph: 'Введіть ПІБ директора',
    tg: 'Канал Telegram',
    tg_ph: 'Вкажіть ім\'я каналу',
    space: 'Розмір простору',
    gb: 'ГБ',
    comments_label: 'Коментар/нотатки',
    comments_ph: 'Введіть коментар',
    comments: 'Нотатки',
    logo: 'Логотип компанії',
    header: 'Шапка сторінки (висота 160 пікс, ширина від 1000 пікс.)',
    sidepanel: 'Бічна панель',
    backcolor: 'Колір фону',
    textcolor: 'Колір тексту',
    font: 'Шрифт',
		create_error: 'Помилка створення клієнта',
		save_error: 'Помилка збереження клієнта',
		saved: 'Клієнт збережено',
		menu_customizing_title: 'Назви та порядок головного меню',
		order_saved: 'Порядок меню записано',
		new_item_name: 'Нове ім\'я',
		export_data: 'Вивантаження всіх даних',
 		export: 'Вивантаження',
		maintenance: {
			heads: {
				convert: 'Перетворення керівників у списковий варіант',
				btn: 'Запустити',
				success: 'Перетворення завершено',
			}
		},
	},
	// Сторінка та діалог повідомлень
	notifs: {
		title: 'Повіщення',
		tab: {
			all: 'Усе',
			blogs: 'Блоги',
			cal: 'Календар',
			groups: 'Групи',
		},
		headers: {
			new_blog_post: 'Новий пост у блозі',
			new_group_post: 'Новий пост у групі',
			feed: 'У стрічці',
			events: 'Події',
			new_user: 'Новий співробітник',
			bd: 'День народження',
			new_group_member: 'Новий учасник у групі',
			event_del: 'Подія видалена',
			event_replaced: 'Подія перенесена',
		},
		errors: {
      no_tmpl_code: 'Не вказано код шаблону',
    },
		channels: {
			system: 'Звук та попап',
			email: 'Ел. пошта',
			notif_center: 'Центр повідомлень',
			tg: 'Телеграм',
      msteams: 'MS Teams',
      slack: 'Slack',
		},
		not_set: 'Не вказано. Встановіть у налаштуваннях повідомлень компанії',
    no_notifs: 'Немає налаштованих шаблонів повідомлень',
		tabs: {
			notifs: 'Налаштування повідомлень',
			tempates: 'Шаблони повідомлень',
		},
		channel_settings: 'Налаштування каналів сповіщень',
		trigger: 'Повіщення',
		check_read: 'Позначити всі як прочитані',
		today: 'Сьогодні',
		yesterday: 'Вчора',
		this_week: 'На цьому тижні',
		earlier: 'Раніше',
		hide: 'Приховати прочитане',
		load_more: 'Завантажити попередні повідомлення',
		nothing: 'Поки немає повідомлень',
		delete: 'Шаблон буде повністю видалено',
    deleted: 'Шаблон успішно видалено',
    only_for_empls: 'Сповістки доступні лише для облікових записів співробітників',
		tmpl_groups: {
			posts: 'Пости (публікації)',
			groups: 'Групи',
			events: 'Події та заходи',
			services: 'Послуги та сервіси',
			polls: 'Опитування та голосування',
			shop: 'Магазин',
			resp: 'Звернення та відгуки',
			other: 'Інші події',
		}
	},//
	shop: {
		title: 'Магазин',
		add_reward: 'Додати нагороду',
		accrue: 'Нарахування',
		nullify: {
			button: 'Обнулити баланс для всіх',
			alert: 'Ви впевнені, що хочете обнулити всі баланси?',
			confirm: 'Обнулити',
		},
		tabs: {
			shop: 'Магазин',
			stat: 'Статистика',
			achievements: 'Досягнення',
			settings: 'Налаштування',
			balances: 'Баланс',
		},
		sort: {
			cost_down: 'По спаданню ціни',
			cost_up: 'За зростанням ціни',
			date: 'За датою додавання',
			no: 'Без сортування',
		},
		errors: {
			ach_saving: 'Помилка збереження досягнення',
			item_del: 'Помилка видалення товару',
			item_was_sent: 'Товар не можна видалити. є продажі',
			coins_not_canceled: 'Нарахування або нагорода не були анульовані'
		},
		no_settings: 'Не задані налаштування магазину',
		buy_btn: 'Придбати',
		name: 'Назва магазину',
		curr_name: 'Назва валюти',
		curr_short: 'Валюта скорочено',
		managers: 'Відповідальні',
		description: 'Опис магазину та валюти',
		curr_icon: 'Іконка валюти',
		ach_name: 'Назва досягнення',
		sett: {
			name: 'Назва магазину',
			name_ph: 'Введіть назву магазину для виведення в заголовку',
			coin: 'Назва валюти',
			coin_ph: 'Введіть повну назву валюти',
			code: 'Код',
			code_ph: 'УЄ',
			descr: 'Опис магазину та валюти',
			descr_ph: 'Введіть опис товарів, доступних співробітникам та іншу корисну інформацію'
		},
		stat_table_accr: {
			empl: 'Співробітник',
			achievements: 'Нагорода',
			coins: 'Монети',
		},
		stat_table_purch: {
			empl: 'Співробітник',
			item: 'Покупка',
			cost: 'Вартість',
		},
		stat_table_all: {
			num: "№",
			time: 'Дата операції',
			empl: 'Співробітник',
			achievement: 'Нагорода',
			item: 'Покупка',
			comment: 'Коментар',
			amount: 'Сума',
		},
		balance_tbl: {
			user: 'Співробітник',
			balance: 'Баланс',
			dept: 'Відділ',
			position: 'Посада',
			achievements: 'Досягнення',
		},
		saved: 'Дані магазини збережені',
		ach: {
			header: 'Досягнення',
			name: 'Назва досягнення',
			new_ph: 'Кращому Бадді',
			pic: 'Додати зображення',
			max: 'Досягнуто максимальну кількість нагород',
			no: 'Досягнення ще не додані',
			no_ach: 'Без нагороди',
			add: 'Додайте досягнення',
			change: 'Зміна досягнення',
			edit: 'Змінити досягнення',
			delete: 'Видалити досягнення',
			del_confirm: 'Видалити досягнення з бази?',
			deleted: 'Досягнення видалено',
			err_fill: 'Не заповнені обов\'язкові поля',
			name_ph: 'Введіть ім\'я нагороди',
      descr_ph: 'Його підопічний з блиском пройшов випробувальний термін',
    },
		auto_ach: {
			greetingPost: 'За подяку',
			poll: 'За опитування',
			profileCompletion: 'За заповнення профілю',
			birthDay: 'За день народження',
			anniversary: 'За годовщину',
			suggestIdea: 'За хорошу ідею',
			title: 'Налаштування автонарахування досягнень та монет',
			empty: 'Виберіть нагороду або монети',
		},
		periodic_ach: {
			workingPeriod: 'За час роботи',
			years: 'Роки',
		},  
		items: {
			header: 'Товар',
			no: 'Поки немає товарів у магазині',
			add: 'Додати товар',
			new: 'Худи',
			edit: 'Змінити товар',
			del: 'Видалити товар',
			del_confirm: 'Видалити товарну позицію?',
			deleted: 'Товар у магазині видалено',
			title: 'Товар у магазині',
			name: 'Назва позиції',
			descr: 'Опис',
			cost: 'Вартість',
			start_amount: 'Початковий залишок',
			amount: 'Залишок',
			now_amount: 'Поточний залишок',
			adding: 'Додавання товару',
			add_amount: 'Додати кількість',
			available: 'Доступний',
			pics: 'Додати зображення',
			size_tip: 'Рекомендований розмір 200х360 пікс',
			err_fill: 'Не заповнені всі обов\'язкові поля товарної позиції',
			err_cost: 'Помилка значення ціни',
			err_amount: 'Помилка у значенні залишку',
			not_available: 'Недоступний',
		},
		coins: {
			name: 'Кійни',
			accrue: 'Нарахування {name}',
			who: 'Кому',
			who_many: 'Розширений відбір співробітників',
			achievement: 'Досягнення',
			descr: 'Коментар',
			public: 'Опублікувати на стіні',
			public2: 'На стіні з\'явиться пост із згадкою співробітника',
			ph_who: 'Виберіть комусь нарахувати монети',
			ph_achieve: 'Виберіть досягнення',
			cancel: 'Нарахування буде анульовано',
      canceled: 'Нарахування успішно анульовано',
			err: {
				no_data: 'Не вибрана кількість ні нагорода',
				amount: 'Не заповнено або неправильне значення кількості',
				too_much: 'Занадто велика або невелика кількість валюти',
				user: 'Не заповнений одержувач монет',
				achieve: 'Не заповнено досягнення',
				accrue: 'Помилка при нарахуванні монет',
				cancel: 'Помилка скасування нарахування',
			},
			success: 'Нарахування успішно виконано',
			added: '{user} отримав {amount} монет',
		},
		buy: {
			title: 'Покупка товару',
			you_buy: 'Ви купуєте',
			you_have: 'У вас накопичено',
			confirm: 'Купити',
			ok: 'Куплю здійснено',
			bought: 'Ви придбали {name}, вітаємо!',
			error: 'Помилка при покупці',
			sa_cannot: 'Суперадмін не може купувати товари клієнта',
			not_enough: 'Не вистачає монет для покупки',
		},
		stats: {
			period: 'За період',
			dept: 'Відділ',
			tab_accr: 'Нарахування',
			tab_purch: 'Покупки',
			all: 'Всі',
			add: 'Додавання',
			buy: 'Покупки',
		},
		post: {
			earn: 'заробив',
			ach: 'досягнення',
			and_coins: 'і {coins} {coin_name}',
		},
	},
  // Таск трекер
  task_tracker: {
		desks: {
			title: 'Дошки завдань',
			no: 'Немає дощок',
			add_title: 'Додати дошку',
			add_lane: 'Додати ще колонку',
			add_task: 'Додати картку',
			sort_date: 'Сортувати за датою',
			change_bg: 'Змінити фон',
			info_title: 'Про дошку',
			fav: 'Вибране',
			fav_r: 'Вибрані дошки',
			my: 'Мої дошки',
			own: 'Я автор',
			інші: 'Інші',
			oftens: 'Часто відвідувані',
			in_groups: 'Дошки в групах',
			owner: 'Власник',
			admins: 'Адміністратори',
			kanban: 'Канбан',
			table: 'Таблиця',
			name: 'Назва дошки',
			filter: 'Фільтр',
			description: 'Опис',
			active: 'Активність',
			status: 'Статус дошки',
			delete_lane: 'Видалити стовпчик і всі завдання',
			confirm_del_lane: 'Точно видалити колонку та завдання в ній?',
			new_lane: 'Нова панель',
			deleted_lane: 'Панель видалена',
			desk_saved: 'Інформацію про дошку збережено',
			copy_url: 'Скопіювати посилання на дошку',
			copied_url: 'Посилання на дошку скопійоване',
			copy: 'Скопіювати дошку',
			show_ended: 'Показати завершені',
			show_ended_tasks: 'Показати завершені картки',
			hide_ended: 'Приховати завершені',
			show_all_tasks: 'Показати всі картки',
			finish_confirm: 'Завершити дошку та всі завдання в ній?',
			finish: 'Завершити дошку',
			finished: 'Завершена',
			restore: 'Відновити',
			deleting: 'Видалення карток',
			all_finish: 'Всі завдання також будуть завершені',
			types: {
				ph: 'Тип завдання',
				open: 'Відкрита',
				active: 'Активна',
				closed: 'Закрита',
				hidden: 'Прихована',
			},
			table_cols: {
				name: 'Назва',
				lane: 'Статус',
				performer: 'Виконавець',
				author: 'Постановник',
				plan: 'Термін',
			},
			tasks_all: 'Усього карток: {n}',
			tasks_finished: 'Завершених: {n}',
			members_can: "Учасники дошки можуть:",
			can_view: 'Переглядати картки',
			can_comment: 'Коментувати картки',
		},
		tasks: {
			lane_new: 'Нова',
			new: 'Нове завдання',
			open: 'Відкрити завдання',
			created: 'Завдання успішно створене',
			saved: 'Завдання збережено',
			no_end_date: 'Безстрокова',
			copied_url: 'Посилання на завдання скопійоване',
			copied_name: 'Назва задачі скопійована',
			checklist_new_item: 'Ще один пункт',
			end_of_week: 'До кінця тижня',
			end_of_month: 'До кінця місяця',
			no_period: 'Плановий термін не заданий',
			by_days: 'Днями',
			by_period: 'Період',
			uploaded: 'Файл завантажений',
			no_users_selected: 'Не вибрані співробітники',
			on_desk: 'У дошці',
			priority: 'Пріоритет',
			i_am: 'Я сам',
			sel_lane: 'Виберіть нову панель',
			conf_move: 'Підтвердити перенесення',
			will_move: 'Завдання буде перенесено до розділу {to}. Продовжити?',
			task_moved: 'Завдання перенесене з "{lane_from}" "{lane_to}"',
			task_moved_ok: 'Завдання перенесене',
			check_list: 'Чек-лист',
			ch_done: 'Виконано: {n} з {count}',
			tags: 'Мітки',
			sel_color: 'Виберіть колір картки',
			color: 'Колір картки',
			sel_border: 'Виберіть колір обведення',
			border: 'Обведення картки',
			attachment: 'Вкладення',
			time_title: 'Терміни виконання',
			time: 'Терміни',
			hours: 'Облік годинника',
			plan_hours: 'План',
			real_hours: 'Факт',
			hours_hint: 'Витрати:<br/>{real} з {plan} г.',
			performer: 'Виконавець',
			no_perf: 'Немає виконавця',
			actions: 'Дії',
			copy: 'Копіювати картку',
			finish: 'Завершити картку',
			finished: 'Завдання успішно закрите',
			is_finished: 'Завдання завершено',
			del: 'Видалити картку',
			owner: 'Постановник',
			responsibles: 'Відповідальні',
			coperformer: 'Виконавець',
			coperformers: 'Виконавці',
			observer: 'Спостерігач',
			observers: 'Спостерігачі',
			created_at: 'Дата створення: {d}',
			upd_at: 'Оновлено: {d}',
			del_confirm: 'Видалити завдання повністю?',
			deleted: 'Завдання успішно видалено',
			confirm_del_file: 'Видалити вкладення?',
			confirm_priority: 'Змінити пріоритет?',
			del_checklist: 'Видалити чек-лист',
			for_adaptation: 'В адаптації',
			to_new_step: 'Ви перейшли на наступний крок',
		},
	},
	// Адаптація
	adapt: {
		title: 'Адаптація',
		tracks: {
			add: 'Додати трек',
			copy: 'Скопіювати трек',
			delete: 'Видалити трек',
			del_confirm: 'При видаленні будуть видалені всі дані про минулі адаптації',
			cant_delete: 'Неможливо видалити трек, є незакінчені адаптації співробітників',
			empty_title: 'Давайте додамо перший адаптаційний трек',
			empty: '',
			tabs: {
				all: 'Всі треки',
				users: 'Учасники',
				analytics: 'Аналітика',
			},
			add_user: 'Додати учасника',
			new_track_name_ph: 'Адаптаційний трек для IT новачка',
			run: 'Запустити',
			stop: 'Зупинити',
			not_active: 'Трек не запущений',
			end: 'Трек завершено',
			errors: 'Трек містить помилки',
			manager: 'Менеджер',
			hr: 'HR',
			manager_ph: 'Виберіть відповідального за модуль',
			settings: {
				dialog_title: 'Налаштування модуля адаптації (онбордингу)',
				mentors: 'Ментори в компанії',
				hrs: 'HR-фахівці',
				achievements: 'Нагороди',
				ach_title: 'Нагороди, що видаються за термін роботи в компанії',
				no_ach: 'Немає нагород за термін роботи',
				polls: 'Опитування',
				polls_title: 'Опитування для проходження після досягнення терміну роботи в компанії',
				days: 'Дні роботи',
				coins: 'Монети',
				incorrect_ach: 'Некоректні налаштування - повторюються нагороди або кількість днів',
				incorrect_polls: 'Некоректні налаштування - повторюються опитування або кількість днів',
				no_polls: 'Немає опитувань, прив\'язаних до терміну роботи',
			},
			go_to_usertrack: 'Перейти до прогресу співробітника',
			no_user_track: 'Немає активної адаптації для цієї ролі',
		},
		step: {
      step_ph: 'Введіть назву кроку',
			create_task: 'Створіть завдання для цього кроку',
			now: 'Одразу ж',
			set: {
				task: 'Завдання',
				achiev: 'Нагорода',
				article: 'Стаття або посилання',
				poll: 'Опитування',
			},
			confirm_del_step: 'Видалити крок?',
			confirm_del: {
				article: 'Підтвердіть видалення статті або посилання',
				poll: 'Підтвердіть видалення опитування',
				achievement: 'Підтвердіть видалення нагороди та досягнення',
				task: 'Підтвердіть видалення завдання',
			},
			task: 'Завдання',
			daysBeforeRun: 'Затримка запуску',
			achievement: 'Нагорода',
			resource: 'Стаття або посилання',
			poll: 'Опитування',
			task_todo: 'Завдання для виконання кроку',
			resume: 'Відновити',
			back: 'Відкотити на крок назад',
			task_expired: 'Термін виконання минув ({days})',
			finished_time: 'Виконана в: {time}',
			notifEnabled: 'Оповіщення про початок кроку'
		},
		track: {
			tabs: {
				info: 'Основне',
				scenario: 'Сценарій',
				users: 'Учасники',
				analytics: 'Аналітика',
				extra: 'Додатково',
			},
			members_tbl: {
				name: 'ПІБ',
				begin: 'Дата початку',
				end: 'Дата закінчення',
				mentor: 'Наставник',
				chief: 'Керівник',
				progress: 'Прогрес',
				actions: 'Дії',
				not_started: 'Не розпочато',
				not_ended: 'Не закінчено',
				fired: 'Звільнений',
				passed: 'Пройшов випробувальний термін',
				delete: 'Видалити адаптацію'
			},
			err: {
				no_name: 'Не заповнена назва треку',
				no_new_user: 'Не вказано співробітника або ментора',
				user_on_track: 'Співробітник вже додано до цього треку',
				step_not_saved: 'Помилка збереження кроку',
				required: 'Не заповнені обов\'язкові поля',
				users_equal: 'Ментор або HR не можуть співпадати зі співробітником на адаптацію',
				del: 'Помилка при видаленні',
				cant_del_step: 'Неможливо видалити крок, якщо адаптація вже в процесі',
			},
			empls_title: 'Співробітники, які проходять адаптацію',
			no_onboarding: 'Ви зараз не проходьте онбординг у нашій компанії',
			back: 'Назад до ваших адаптацій',
			admin: 'Адміністратор',
			mentor: 'Наставник',
			chief: 'Керівник',
			mentors: 'Наставники',
			hr: 'HR-менеджер',
			hrs: 'HR-менеджери',
			novice: 'Новачок',
			users: 'Учасники',
			time: 'Термін дії треку',
			who: 'Кому',
			no_stages: 'Немає жодного етапу',
			add_stage: 'Додати етап',
			add_step: 'Додати крок',
			add_user: 'Додати співробітника',
			stage_ph: 'Введіть назву етапу сценарію',
			stage_added: 'Етап доданий',
			no_steps: 'Немає кроків на даному етапі',
			step_added: 'Крок доданий успішно',
			step_saved: 'Крок збережений успішно',
			add: 'Додати новий адаптаційний трек',
			new_step: 'Новий крок',
			progress: 'Прогрес',
			tasks: 'Завдання',
			starting_label: 'Створювати кроки сценарію, рахуючи від:',
			time_from_start: 'Початки адаптації',
			time_from_task: 'Поперед. кроку',
			task_title: 'Завдання для виконання',
			poll_title: 'Опитування',
			resource_title: 'Ресурс для ознайомлення',
			ach: 'Винагорода',
			article_link: 'Стаття або зовнішній ресурс',
			poll: 'Перевірочне опитування',
			select_ach: 'Виберіть нагороду за завершення етапу',
			select_kb: 'Вибрати з бази знань',
			select_coins: 'Вкажіть кількість монет',
			created: 'Новий трек успішно створено',
			new_user_ph: 'Виберіть нового учасника треку',
			user_added: 'Новичок успішно додано до треку',
			no_mentor: 'Немає ментора',
			no_chief: 'Немає керівника',
			no_empls: 'Немає активних адаптацій, де ви б брали участь',
			no_empls_for_mentor: 'Немає співробітників, у яких ви ментор',
			no_empls_for_chief: 'Немає співробітників з вашого відділу',
			confirm_del_stage: 'Видалити етап?',
			times: 'Терміни плану адаптації',
			no_time: 'Безстроковий',
			end: 'Завершення треку',
			you_are: 'Ваша роль: ',
			chief_of: 'Ви керівник відділу "{dept}"',
			changed: 'Адаптація змінена',
			changed_chief: 'Керівник змінено в адаптації',
			changed_mentor: 'Ментор змінено в адаптації',
			changed_hr: 'HR змінено в адаптації',
		},
	},
	vacations: {
		title: 'Відпустка',
		title_sched: 'Графік відпусток',
		block: 'З {start} до {end}',
		add_vac: 'Додати відпустку',
		days_left: 'У вас залишилося {days} відпустки',
		too_much: '. Число днів перевищує ваш залишок',
		delete_confirm: 'Видалити відпустку з графіка?',
		deleted: 'Відпустка видалена',
		past_date: 'Вкажіть дату пізніше за поточну',
		date_cross_vac: 'Помилка, відпустка перетинається з іншою відпусткою',
		days_not_enough: 'Не вистачає залишку відпустки',
		past_period_alert: 'Відпустка буде створена за минулий період',
	},
}
